import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Typography,
} from "@mui/material";
import { FormModalDialogProps } from "../../../models/ModalDialogProps";
import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { ProductLayoutFieldDetail } from "../../../models/Items/ProductLayoutFieldDetail";
import { ProductLayoutCategoryDetail } from "../../../models/Items/ProductLayoutCategoryDetail";

const SecurityGroupFormModal = (props: FormModalDialogProps & { isButtonDisabled: boolean }) => {
  const { isOpen, onClose, formik, isButtonDisabled } = props;
  const [fields, setFields] = useState<ProductLayoutFieldDetail[]>([]);

  useEffect(() => {
    fetchFieldList();
  }, [])

  const fetchFieldList = async () => {
    try {
      const response = await api
        .get<ApiResponse<ProductLayoutCategoryDetail[]>>(`/PickList/layoutFields`);
      if (response.isSuccess) {
        const { data } = response;
        if (data?.length > 0) {
          // Flatten fields array from the api response data
          const flattenedFields = data.flatMap(category => category.fields);
          setFields(flattenedFields);
        }
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
      <DialogActions>
        <Button onClick={onClose}>x</Button>
      </DialogActions>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="groupName"
            name="groupName"
            label={
              (formik.touched.groupName && formik.errors.groupName) ||
              "Enter Description"
            }
            type="text"
            fullWidth
            value={formik.values.groupName}
            onChange={formik.handleChange}
            error={
              !!(formik.touched.groupName && formik.errors.groupName)
            }
            onBlur={formik.handleBlur}
          />
          {(formik.touched.securityGroupFields && formik.errors.securityGroupFields) ?
            <Typography component="h4" color="error">Select at least one Field for this Security Group</Typography> :
            <h4>Assign Fields to Security Group</h4>}
          <FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                maxHeight: "400px",
                overflowX: "auto",
              }}
            >
              {fields
                .map((field) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.securityGroupFields?.some(st => st.fieldName == field.fieldName)}
                        onChange={() => {
                          const securityGroupFields = formik.values.securityGroupFields;
                          if (securityGroupFields?.some(st => st.fieldName == field.fieldName)) {
                            // If field is already selected, remove it
                            const updatedSecurityGroupList = securityGroupFields?.filter(
                              (st) => st.fieldName !== field.fieldName
                            );
                            formik.setFieldValue("securityGroupFields", updatedSecurityGroupList);
                          }
                          else {
                            // If field is not selected, add it
                            formik.setFieldValue("securityGroupFields", [
                              ...securityGroupFields,
                              field,
                            ]);
                          }
                        }}
                        name="securityGroupFields"
                        />
                      }
                    key={`securityGroupFields.${field.fieldName}`}
                    label={field.fieldName}
                  />
                ))}
            </div>
          </FormGroup>
          <h4>Set Active</h4>
          <RadioGroup
            row
            name="isActive"
            value={formik.values.isActive}
            onChange={(event) => formik.handleChange(event)}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" disabled={isButtonDisabled}>
            {formik.values.id > 0
              ? "Update Security Group"
              : "Add Security Group"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SecurityGroupFormModal;
