import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import {
  GRID_ACTION_EDIT_ICON,
} from "../../../components/GridUtilityComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import AssignmentModal from "./AssignmentModal";
import GridCellChipsContent from "../../../components/GridCellChipsContent";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { Assignment } from "../../../models/Assignment";
import ConfirmationModal from "../../../components/ConfirmationModal";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function () {
  const [assignmentList, setAssignmentList] = useState<Assignment[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [assignmentParams, setAssignmentParams] = useState(null);
  const [showStatusChangeConfirmModal, setShowStatusChangeConfirmModal] = useState<boolean>(false);
  const formDefaultValue = {
    id: 0,
    name: "",
    hierarchy: null,
    assignmentGroups: [],
    isActive: true,
  };

  const sessionService = SessionService.getInstance();
  const manageAssignmentsPermission = sessionService.hasPermission(Permissions.ManageAssignments);


  const AssignmentGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 80 },
    { field: "hierarchy", headerName: "Hierarchy", width: 100 },
    { field: "name", headerName: "Description", width: 150 },
    {
      field: "assignmentGroups",
      headerName: "Security Groups",
      type: "Array",
      width: 480,
      sortable: false,
      renderCell: (params: any) => {
        if (!params.value) {
          return params.value;
        } else {
          return (
            <GridCellChipsContent cellData={params.row.assignmentGroups} keyField="id" displayTextFiled="securityGroupDisplayText" />
          );
        }
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable: false,
      renderCell: (params: any) => (
        <Switch
          checked={params.value}
          onChange={(e) => {
            setAssignmentParams({
              ...params.row,
              isActive: e.target.checked,
            });
            setShowStatusChangeConfirmModal(true);
          }}
          color="primary"
          inputProps={{ "aria-label": "controlled" }}
          disabled={!manageAssignmentsPermission}
        />
      ),
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 100,
      valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClick),
      ],
    },
  ];

  const handleEditClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClick = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    fetchAssignmentList();
  }, []);

  const fetchAssignmentList = async () => {
    const resp = await api.get<ApiResponse<Assignment[]>>(
      "/Assignment"
    );

    const sortedList = resp?.data?.sort((rec1, rec2) => rec2.id - rec1.id);
    setAssignmentList(sortedList || []);
  };

  const updateAssignmentActive = () => {
    api
      .put<ApiResponse<boolean>>("/Assignment", assignmentParams)
      .then(async (response: any) => {
        if (response.isSuccess) {
          toast.success(response?.message);
          await fetchAssignmentList();
          handleConfirmationModalClose();
        } else {
          handleConfirmationModalClose();
          throw new Error(response?.message);
        }
      })
      .catch((error) => {
        handleConfirmationModalClose();
        console.error(error?.message);
      });
  };

  const formik = useFormik({
    initialValues: formDefaultValue,
    validateOnChange: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .min(1, "Description must be at least 1 characters")
        .max(100, "Description must be at most 40 characters")
        .required("Description is Required")
        .test(
          "check-name-duplicate",
          "Assignment with this name already exists",
          function async(value) {
            return new Promise((resolve) => {
              const isAssignmentNameDuplicate = assignmentList.some(
                (item) => item.name.toLowerCase() === value.toLowerCase() && item.id !== formik.values.id
              );
              isAssignmentNameDuplicate ? resolve(false) : resolve(true);
            });
          }
        ),
      hierarchy: Yup.number()
        .required("Hierarchy is Required")
        .min(1, "Hierarchy must be greater than 0")
        .test(
          "check-hierarchy-duplicate",
          "Hierarchy already exists",
          function async(value) {
            return new Promise((resolve) => {
              const isHierarchyDuplicate = assignmentList.some(
                (item) => item.hierarchy === value && item.id !== formik.values.id
              );
              isHierarchyDuplicate ? resolve(false) : resolve(true);
            });
          }
        ),
      assignmentGroups: Yup.array().min(1, "Please select at least 1 security group for this assignment"),
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Assignment", {
            id: values.id,
            name: values.name.trim(),
            hierarchy: values.hierarchy,
            isActive: JSON.parse(`${values.isActive}`),
            assignmentGroups: values.assignmentGroups,
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchAssignmentList();
            }
          })
          .catch((error) => {
            console.error(error?.message);
           });
      } else {
        api
          .post<ApiResponse<number>>("/Assignment", {
            name: values.name.trim(),
            hierarchy: values.hierarchy,
            isActive: JSON.parse(`${values.isActive}`),
            securityGroupIds: values.assignmentGroups?.map(st => st.securityGroupId),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchAssignmentList();
            } else {
              throw new Error(response.message);
            }
          })
          .catch((error) => {
            console.error(error?.message);
           });
      }
    },
  });

  const handleActiveStatusChangeConfirm = () => {
    updateAssignmentActive();
  };

  const handleConfirmationModalClose = () => {
    setAssignmentParams(null);
    setShowStatusChangeConfirmModal(false);
  };

  return (
    <>
      {showAddUpdateModal && <AssignmentModal
        isOpen={showAddUpdateModal}
        onClose={handleCloseModal}
        formik={formik}
        isButtonDisabled={disableSubmit}
      />}
      <ConfirmationModal
        isOpen={showStatusChangeConfirmModal}
        onClose={handleConfirmationModalClose}
        title={"Confirm Status Change"}
        message={assignmentParams?.isActive ? `Are you sure you want to activate this Assignment?` : `Are you sure you want to deactivate this Assignment?`}
        handleConfirmYes={handleActiveStatusChangeConfirm}
        handleConfirmNo={handleConfirmationModalClose}
      />
      {manageAssignmentsPermission &&
        <Button variant="contained" onClick={handleAddClick}>
          Add Assignment
        </Button>}

      <SettingGrid
        data={assignmentList}
        column={AssignmentGridCollumns}
        sortByField="id"
        sortByOrder={"desc"}
        rowHeightGetter={() => 'auto'}
        columnVisibility={{
          actions: manageAssignmentsPermission,
        }}
      />
    </>
  );
}

