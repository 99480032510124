/* eslint-disable no-template-curly-in-string */
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  Divider,
  FormControl,
  Typography,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { CSSDefaults } from "../models/GlobalConstants";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { AttachmentType } from "../models/AttachmentType";
import imageNotAvailable from "../no-image.png";
import { THUMBNAIL_IMAGE_API_URL, validExtensions } from "../pages/Products/EditItem/Constants";
import ImageSliderView, { ImageDetail } from "./ImageSliderView";
import SessionService from "../services/SessionService";
import { Permissions } from "../models/Enum";
import DownloadIcon from '@mui/icons-material/Download';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import GetIconByFileType from "./GetIconByFileType";
import GetFileType from "./GetFileType";
import PDFViewModal, { PDFDetail } from "../pages/Report/ViewAttachment/PDFViewModal";
import VisibilityIcon from '@mui/icons-material/Visibility';

type AttachmentsUploaderProps = {
  title: string;
  attachments?: MultipleAttachment[];
  onChange: (attachments: any, index?: number) => void;
  onRemove?: (index: number) => void;
  handleAttachmentDownload?: (fileName: string) => void;
  attachmentTypeDropdownOptions: AttachmentType[];
  setShowFab? :(value: boolean) => void;
};

export type MultipleAttachment = {
  attachId?: number;
  fileName: string;
  file?: File;
  attachType: number;
  attachmentTypeDisplayText?: string;
  lvl?: string;
  disabled: boolean;
}

const AttachmentsUploader = (props: AttachmentsUploaderProps) => {

  const {
    title,
    attachments,
    onChange,
    onRemove,
    handleAttachmentDownload,
    attachmentTypeDropdownOptions,
    setShowFab
  } = props;

  const [attachmentTypes, setAttachmentTypes] = useState<any>([]);
  const [totalFileSize, setTotalFileSize] = useState<string>("");
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<ImageDetail[]>([]);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedImageSlide, setSelectedImageSlide] = useState<number>(0);
  const maxSizeInBytes = 100 * 1024 * 1024; // 100 MB

  const sessionService = SessionService.getInstance();

  const closeSlider = () => {
    setIsSliderOpen(false);
    setIsPDFViewOpen(false);
    setShowFab(true);
  };

  useEffect(() => {
    if (attachmentTypeDropdownOptions?.length > 0) {

      const setAttachmentTypeOptions = attachmentTypeDropdownOptions.map((attachmentTypeItem) => {
        return {
          attachmentType: attachmentTypeItem.id,
          attachmentTypeDisplayText: attachmentTypeItem.attachType,
          lvl: attachmentTypeItem.lvl
        }
      })

      setAttachmentTypes(setAttachmentTypeOptions || []);
    }
  }, [attachmentTypeDropdownOptions]);

  const handleImageSlider = (attachId: number) => {
    const filterImageAttachments = attachments.filter(a => a.disabled === true && GetFileType(a.fileName).isImage)

    const images = filterImageAttachments.map((item) => {
      return {
          fileName: item.fileName,
          imageUrl: `${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(item.fileName)}&height=600&width=600`,
      }
    });

    if (images && images.length > 0) {
      setImages(images);
      setIsSliderOpen(true);
      setShowFab(false);
      setSelectedImageSlide(filterImageAttachments.findIndex(num => num.attachId === attachId));
    }
  };

  const prepareFileUploadChange = (event, fileUploadType) => {
    let fileList;
    if (fileUploadType === "fileUploadButtonClick") {
      fileList = event.target.files || [];
    } else {
      fileList = event.dataTransfer.files;
    }

    const fileArray: File[] = Array.from(fileList);
    const validFilesArray = []; // Array to store valid files

    if (fileArray && fileArray.length > 0) {

      // Loop through all the files
      fileArray.forEach((file) => {
        const { name, size } = file;

        // Combine all valid file extensions into a single array
        const allValidExtensions = Object.values(validExtensions);

        // Check if the file has a valid extension and size
        if (allValidExtensions.includes(GetFileType(name).fileExtension)) {
          if (size > maxSizeInBytes) {
            toast.error(`File size for "${name}" exceeds the 100 MB limit`);
          } else {
            // Add valid files to the array
            validFilesArray.push({
              attachmentType: 0,
              file: file,
            });
          }
        } else {
          toast.error(`Invalid file extension for "${name}"`);
        }
      });

      // Pass the array of valid files to onChange
      if (validFilesArray.length > 0) {
        onChange(validFilesArray);
      }

      // Reset the input after all files are processed
      if (fileUploadType === "fileUploadButtonClick") {
        event.target.value = ""; // reset the file input
        fileInputRef.current.files = null;
      }
    }
  };
  

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    prepareFileUploadChange(event, "fileUploadButtonClick");
  };

  const handleAttachmentTypeChange = (selectedItem, index) => {
    const newStateValue = {
      attachmentType: selectedItem.attachmentType,
      attachmentTypeDisplayText: selectedItem.attachmentTypeDisplayText,
      level: selectedItem.lvl,
    };
    onChange(newStateValue, index);
  };

  useEffect(() => {
    calculateFileSize(attachments);
  }, [attachments])

  const calculateFileSize = (attachmentsData: MultipleAttachment[]) => {
    const filterAttachments = attachmentsData.filter(a => a.disabled === false);

    if (filterAttachments && filterAttachments.length > 0) {
      const totalSize = filterAttachments.reduce(
        (total, file) => total + file.file.size,
        0
      );

      const formattedSize = formatBytes(totalSize); // Format bytes for better readability
      setTotalFileSize(formattedSize);
    }
    else {
      setTotalFileSize("");
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    prepareFileUploadChange(event, "fileDropUpload");
  };

  // const getFileType = (fileName: string) => {
  //   const validImageExtensions = new Set([
  //     validExtensions.jpg,
  //     validExtensions.jpeg,
  //     validExtensions.png,
  //     validExtensions.tiff,
  //     validExtensions.tif
  //   ]);
  //   const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
  //   const isImage = validImageExtensions.has(fileExtension);
  //   return { fileExtension, isImage };
  // };

  const getFileNameTextContent = (fileName) => (
    <Typography
      variant="body2"
      color="text.secondary"
      style={{
        margin: "10px 0",
        textAlign: "center",
        whiteSpace: "normal",
        wordBreak: "break-word",
        minHeight: "41px"
      }}
    >
      {`${fileName}`}
    </Typography>
  );

  // const getIconByFileType = (fileName) => {
  //   const fileType = getFileType(fileName).fileExtension;

  //   switch (fileType) {
  //     case validExtensions.pdf:
  //       return <PictureAsPdfOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
  //     case validExtensions.doc:
  //     case validExtensions.docx:
  //       return <ArticleOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
  //     case validExtensions.ai:
  //       return <InsertDriveFileOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
  //     case validExtensions.zip:
  //       return <FolderZipOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
  //     case validExtensions.tif:
  //     case validExtensions.tiff:
  //       return <ImageOutlinedIcon sx={{ fontSize: 40, opacity: 0.54 }} />;
  //   }
  // };

  const filterInputStyle = {
    flex: "0 0 calc(20% - 10px)",
    margin: "8px 5px",
    "& .MuiOutlinedInput-root": {
      padding: 0,
      input: {
        paddingBottom: 0,
      },
    },
  };


  const [pdfDetailView, setPDFDetailView] = useState<PDFDetail> ();
  const [isPDFViewOpen, setIsPDFViewOpen] = useState(false);

  const handlePDFViewer = (fileName) => {
    setPDFDetailView({
      fileName: fileName,
    });
    setIsPDFViewOpen(true);
    setShowFab(false);
  }

  return (
    <>
      { isPDFViewOpen && <PDFViewModal
          pdf={pdfDetailView}
          isOpen = {isPDFViewOpen}
          onClose = {closeSlider}
        />
      }

      {images.length > 0 &&
        <ImageSliderView images={images} selectedImageSlide={selectedImageSlide} isOpen={isSliderOpen} onClose={closeSlider} />}
      <Box
        flexGrow="1"
        display="flex"
        sx={{
          border: "1px solid",
          borderRadius: "4px",
          boxShadow: "5px 5px 8px 2px #888888",
          padding: "15px",
          margin: "20px 0",
          ul: {
            listStyle: "none",
            display: "flex",
            flexDirection: "row",
            li: {
              padding: 1,
              border: `solid 1px ${CSSDefaults.primaryColor}`,
              margin: 1,
              borderRadius: "2px",
            },
          },
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div style={{ width: "100%" }}>
          {sessionService.hasPermission(Permissions.ManageAttachments)
            && <div
              style={{
                margin: "0 0 10px",
                padding: "20px",
                border: "2px dashed #ccc",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <input
                ref={fileInputRef}
                id="contained-button-file"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple // Enables multiple file upload
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<UploadFileOutlinedIcon />}
                >
                  {title}
                </Button>
              </label>
              <p>or</p>
              <p>Drag and drop your files here</p>
            </div>}
          {attachments && attachments.length > 0 && (
            <>
              {totalFileSize && (
                <div>
                  <Typography variant="h6" gutterBottom>
                    Selected Files (Total Size : {totalFileSize})
                  </Typography>
                  <Divider />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  float: "none",
                  padding: "10px 0 0",
                  verticalAlign: "top",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {attachments.length > 0 &&
                  attachments
                    .map((attachmentItem, index) => (
                      <div
                        key={index + 1}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "7px 0 0",
                        }}
                      >
                        <div style={{ margin: "8px 15px 8px 0" }}>
                          <Card sx={{ width: 240, overflow: "visible" }}>
                            <CardContent
                              sx={{ padding: 1, paddingBottom: "0 !important", position: "relative" }}
                            >
                              {sessionService.hasPermission(Permissions.ManageAttachments)
                                && <CloseIcon
                                  onClick={() => onRemove(index)}
                                  style={{
                                    position: "absolute",
                                    top: "-15px",
                                    right: "-10px",
                                    cursor: "pointer",
                                    zIndex: 1,
                                    padding: 4,
                                    backgroundColor: CSSDefaults.headerFontColor,
                                    borderRadius: "50%",
                                    color: CSSDefaults.headerBgColor,
                                    fontSize: "1.2rem",
                                  }}
                                />}
                              {((attachmentItem.disabled) &&
                                (GetFileType(attachmentItem.fileName).isImage)) ? (
                                <Box className="image-thumbnail-container">
                                  <CardMedia
                                    component="img"
                                    src={`${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(attachmentItem.fileName)}`}
                                    alt={`Attachment ${attachmentItem.fileName}`}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imageNotAvailable;
                                    }}
                                    style={{ position: 'relative', objectFit: "cover", width: "auto", maxWidth: "100%", maxHeight: "66px", margin: "0 auto" }}
                                  />
                                  <div style={{ position: 'absolute', display: 'flex', gap: '5px' }}>
                                    <IconButton
                                      className="attachment-hover-icon-button"
                                      size="small"
                                      onClick={() => handleAttachmentDownload(attachmentItem.fileName)}
                                    >
                                      <DownloadIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                      className="attachment-hover-icon-button"
                                      size="small"
                                      onClick={() => handleImageSlider(attachmentItem.attachId)}
                                    >
                                      <SlideshowIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </Box>
                              ) : (!attachmentItem.disabled &&
                                GetFileType(attachmentItem.fileName).isImage &&
                                // This below condition is used to not display an actual image of new uploaded attachment
                                (GetFileType(attachmentItem.fileName).fileExtension !== validExtensions.tiff) &&
                                (GetFileType(attachmentItem.fileName).fileExtension !== validExtensions.tif) &&
                                <Box className="image-thumbnail-container">
                                  <CardMedia
                                    component="img"
                                    image={URL.createObjectURL(attachmentItem.file)}
                                    alt={`Attachment ${attachmentItem.fileName}`}
                                    style={{ objectFit: "cover", width: "auto", maxWidth: "100%", maxHeight: "66px", margin: "0 auto" }}
                                  />
                                </Box>
                              )}
                              {((!GetFileType(attachmentItem.fileName).isImage) ||
                                ((!attachmentItem.disabled) && GetFileType(attachmentItem.fileName).fileExtension == validExtensions.tif) ||
                                ((!attachmentItem.disabled) && GetFileType(attachmentItem.fileName).fileExtension == validExtensions.tiff)) &&
                                <div
                                  style={{
                                    backgroundColor: "rgba(0,0,0,.05)",
                                    height: "66px",
                                    textAlign: "center",
                                    cursor: attachmentItem.disabled ? "pointer" : "default"
                                  }}
                                  className="image-thumbnail-container"
                                >
                                  {GetIconByFileType(attachmentItem.fileName)}
                                  {attachmentItem.disabled &&
                                    <div style={{ position: 'absolute', display: 'flex', gap: '5px'  }}>
                                      <IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handleAttachmentDownload(attachmentItem.fileName)}
                                      >
                                        <DownloadIcon fontSize="small" />
                                      </IconButton>
                                      {GetFileType(attachmentItem.fileName).isPdf  && (<IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handlePDFViewer(attachmentItem.fileName)}
                                    >
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>)}
                                    </div>}
                                </div>}
                              {getFileNameTextContent(attachmentItem.fileName)}
                            </CardContent>
                          </Card>
                          <FormControl style={{ margin: "15px 0", width: "240px" }}>
                            <Autocomplete
                              sx={filterInputStyle}
                              value={
                                attachmentItem.attachmentTypeDisplayText
                                  ?
                                  {
                                    attachmentTypeDisplayText: attachmentItem.attachmentTypeDisplayText,
                                    lvl: attachmentItem.lvl
                                  }
                                  : null
                              }
                              onChange={(event, selectedItem) => handleAttachmentTypeChange(selectedItem, index)}
                              options={attachmentTypes || []}
                              getOptionLabel={(option) =>
                                (`${option.lvl} - ${option.attachmentTypeDisplayText}`)
                              }
                              disableClearable
                              disabled={!!attachmentItem.disabled}
                              style={{ backgroundColor: attachmentItem.disabled ? "#EBEBE4" : "#FFFFFF" }}
                              renderInput={(params) => (
                                <Tooltip title={attachmentItem.disabled ? attachmentItem.attachmentTypeDisplayText : ""} arrow>
                                  <TextField
                                    key={params.id}
                                    error={
                                      Boolean(attachmentItem.attachType == 0)
                                    }
                                    label={"Attachment Type"}
                                    {...params}
                                    variant="outlined"
                                  />
                                </Tooltip>
                              )}
                            />
                          </FormControl>
                        </div>
                      </div>
                    ))
                }
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default AttachmentsUploader;
