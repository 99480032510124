import imageNotAvailable from "../../../no-image.png";

interface ImageViewModalProp{
    images: ImageDetail;
    isOpen: boolean,
    onClose: () => void;
}

export type ImageDetail = {
    imageUrl: string;
    fileName: string;
}

const ImageViewModal = (props: ImageViewModalProp) => {
    const {images,isOpen, onClose} = props;

    if (!isOpen) return null;

    return(
        <>
            <div className="image-slider-overlay">
                <div className="image-slider-container">
                    <span className="close-btn" onClick={onClose}>&times;</span>
                        <img
                            src={images.imageUrl}
                            alt={`Attachment ${images.fileName}`}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imageNotAvailable;
                            }}
                        />
                </div>
            </div>
        </>
    )

};

export default ImageViewModal;