import { Box, Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControlLabel, 
    FormGroup, 
    Grid, 
    IconButton, 
    Switch, 
    Typography 
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { NOTIFICATION_TYPE_FRIENDLY_NAME } from "../utils/constants";

interface ManageNotificationModalProps {
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;
};

const ManageNotificationModal = (props: ManageNotificationModalProps) => {
    const {isOpen, onClose, formik} = props;
    
    const notificationCheckBox = () => (
        <>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        Notification Type
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        In App
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        Email
                    </Typography>
                </Grid>
            </Grid>
            <FormGroup>
                {formik.values.notifications.map((notification, index) => (
                    <Grid container key={index} spacing={2} alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" >
                                {NOTIFICATION_TYPE_FRIENDLY_NAME[notification.notificationType]}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Switch 
                                    name={`notifications.${index}.receiveAppNotification`} 
                                    checked = {notification.receiveAppNotification}
                                    onChange={formik.handleChange}
                                />}
                                label = ""
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={<Switch 
                                    name={`notifications.${index}.receiveEmail`}
                                    checked = {notification.receiveEmail}
                                    onChange={formik.handleChange} 
                                />}
                                label=""
                            />
                        </Grid>
                    </Grid>
                ))}
            </FormGroup>
        </>
    );
    
    return(
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                    <Typography variant="h5">Manage Notifications Subscription</Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {notificationCheckBox()}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" type = "submit">Update</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default ManageNotificationModal;