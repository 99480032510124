import { validExtensions } from "../pages/Products/EditItem/Constants";

const getFileType = (fileName: string) => {
    const validImageExtensions = new Set([
      validExtensions.jpg,
      validExtensions.jpeg,
      validExtensions.png,
      validExtensions.tiff,
      validExtensions.tif
    ]);

    const validPDFExtensions = new Set([
      validExtensions.pdf,
    ]);
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
    const isImage = validImageExtensions.has(fileExtension);
    const isPdf = validPDFExtensions.has(fileExtension)
    return { fileExtension, isImage, isPdf};
};

export default getFileType;