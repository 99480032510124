import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useEffect, useState } from "react";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import moment from "moment";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import DataFieldTypeModal from "./DataFieldTypeModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DataFieldDetail, DataFieldWithTypes } from "../../../models/DataFieldDetail";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { SessionDetails } from "../../../models/SessionDetails";
import GridCellChipsContent from "../../../components/GridCellChipsContent";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function DataFieldTypeSetting() {
  const DataFieldTypeGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "field", headerName: "Data Field", width: 200 },
    {
      field: "types",
      headerName: "Data Type",
      width: 700,
      sortable: false,
      renderCell: (params: any) => {
        if (!params.value) {
          return params.value;
        } else {
          return (
            <GridCellChipsContent keyField = "id" displayTextFiled = "description" cellData = {params.row.types}/>
          );
        }
      },
    },
    { field: "isActive", headerName: "Is Active", sortable: false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked = {params.value}
            onChange={async (e) => {
              await updateDataFieldType(params.row, e.target.checked);
            }}
            color="primary"
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      )
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    },
    { field: "actions", type: "actions", headerName: "Actions", 
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
        GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
      ]
    }
  ];

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const [dataFieldTypeList, setDataFieldTypeList] = useState<DataFieldWithTypes[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    fetchDataList();
  }, []);

  const fetchDataList = async () => {
    const getDataFieldTypeResponse = await api.get<
      ApiResponse<DataFieldWithTypes[]>
    >("/Picklist/data-fields");
    setDataFieldTypeList(getDataFieldTypeResponse?.data || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({...gridParams.row});
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({...gridParams.row});
  };

  const handleClose = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  }

  //updating datafield only for the switch in active 
  const updateDataFieldType = async (row: DataFieldDetail, isActive: boolean) => {
    const data = {
      ...row,
      isActive: isActive,
      types: null
    }
    try{
      api
        .put<ApiResponse<boolean>>("/Picklist/data-field", data)
        .then(async (response: any) => {
          if(response.isSuccess) {
            toast.success(response.message);
            await fetchDataList();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch(err: any) {
      toast.error(err.message);
    }
  };

  const deleteSubmit = () => {
    try{
      api
        .delete<ApiResponse<SessionDetails>>(`/Picklist/data-field/${formik.values.id}`)
        .then(async (response: any) => {
          if(response.isSuccess) {
            setDeleteModalOpen(false);
            toast.success(response.message);
            formik.resetForm();
            await fetchDataList();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {})
      } catch(err: any) {
        toast.error(err.message);
      }
  };

  const formik = useFormik<DataFieldWithTypes>({
    initialValues: {
      id: 0,
      field: '',
      types: [],
      isActive: true,
    },
    validationSchema: Yup.object({
      types: Yup.array().min(1,"Please select at least one Data Types for this Data Field"),
      field: Yup.string()
      .trim()
      .min(1, "Data Type must be at least 1 characters")
      .max(40, "Date Type must be at most 40 characters")
      .required("Data Field is required")
      .test("field-duplicate-check" ,"Data Field already exists", 
      (value) => {
        return !dataFieldTypeList.some((item) => item.field.trim().toLowerCase() === value.trim().toLowerCase() &&
          item.id !== formik.values.id
        )
      }
      )
    }),
    onSubmit: async (values) =>{
      setDisableSubmit(true); // Set disableSubmit to true when the form is submitted
      if (values.id > 0) {
        api 
          .put<ApiResponse<number>>("/Picklist/data-field", {
            id: values.id,
            field: values.field,
            types: values.types.map(t => t.id),
            isActive: JSON.parse(`${formik.values.isActive}`)
          })
          .then(async (response: any) => {
          if(response.isSuccess) {
            setShowAddUpdateModal(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchDataList();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
      } else {
        api 
          .post<ApiResponse<number>>("/Picklist/data-field", {
            id: values.id,
            field: values.field,
            types: values.types.map(t => t.id),
            isActive: JSON.parse(`${formik.values.isActive}`)
          })
          .then(async (response: any) => {
          if(response.isSuccess) {
            setShowAddUpdateModal(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchDataList();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
      }
    }  
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button variant="contained" onClick = {handleAddClickOpen}>
          Add Data Field
        </Button>}
        {showAddUpdateModal && <DataFieldTypeModal 
            isOpen={showAddUpdateModal} 
            onClose={handleClose} 
            formik={formik}
            isButtonDisabled={disableSubmit}
          />
        } 
        <DeleteValidationModal 
          addModalOpen={deleteModalOpen} 
          handleCloseModal={handleClose} 
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Data Field"}
        />
      </div>
      <SettingGrid
        data={dataFieldTypeList}
        column={DataFieldTypeGridCollumns}
        sortByField="id"
        sortByOrder="desc"
        rowHeightGetter={() => 'auto'}
        columnVisibility={{
          actions: manageAppSetting,
        }}

      />
    </>
  );
}
