import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem, Popover, TextField, Typography } from "@mui/material";
import { CSSDefaults } from "../models/GlobalConstants";
import { User } from "../models/User";
import SessionService from "../services/SessionService";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { ProductComment } from "../models/ProductComment";
import ConfirmationModal from "./ConfirmationModal";
import { Permissions } from "../models/Enum";
import { Item } from "../models/Items/Item";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { ApiResponse } from "../models/ApiResponse";
import api from "../services/ApiService";

type ProductCommentsProps = {
  commentList: ProductComment[];
  onSubmit: (newComment: NewComment) => void;
  onDelete: (id: number) => void;
  editItem: Item;
};

export type NewComment = {
  id: number;
  comment: string;
  isBaseCodeChecked: boolean;
}

interface CommentTagUserOption {
  id: number;
  name: string;
  email: string;
};

const ProductComments = (props: ProductCommentsProps) => {

  const {
    commentList,
    onSubmit,
    onDelete,
    editItem
  } = props;

  const sessionService = SessionService.getInstance();
  const currentUser: User = sessionService.getCurrentUser();

  const initialNewComment = {
    id: 0,
    comment: "",
    isBaseCodeChecked: !!((editItem?.baseCode || editItem?.pdCode)),
  };

  const [newComment, setNewComment] = useState<NewComment>(initialNewComment);
  const [deleteCommentId, setDeleteCommentId] = useState<number | null>(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const quillRef = useRef<ReactQuill>(null);
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const [userOptionsList, setUserOptionsList] = useState<CommentTagUserOption[]>([]);
  const [userSuggestionList, setUserSuggestionList] = useState<CommentTagUserOption[]>(userOptionsList);
  const [searchingEnabled, setSearchingEnabled] = useState(true);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const pageQueryParams = queryParameters.get("page");

  useEffect(() => {
    setNewComment(initialNewComment);
    setDeleteCommentId(null);
  }, [commentList]);

  useEffect(() => {
    getUserList();
  }, []);

  /* 
    This function is used to get user drop down options from api
   */
  const getUserList = () => {
    try {
      api
        .get<ApiResponse<User[]>>(`/UserRole`)
        .then((response: ApiResponse<User[]>) => {
          if (response.isSuccess) {
            if (response.data?.length > 0) {
              const users = response.data.filter((ul) => ul.isActive === true).map(ul => {
                return {
                  id: ul.id,
                  name: ul.name,
                  email: ul.email
                }
              });
              setUserOptionsList(users);
            }
          } else {
            throw new Error(response?.message);
          }
        })
        .catch((error) => {
          console.error("Error while fetching list of users for Comment Tag options", error);
        });
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  /**
   * Formats the date string into a readable format.
   */
  const renderFormattedDate = (dateString: string) => {
    return moment(dateString).format('MMMM D');
  };

  /**
   * Extracts and returns the first character of the user's name.
   */
  const renderUserInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  /**
   * Handles the confirmation for deleting a comment.
   */
  const handleDeleteConfirm = () => {
    onDelete(deleteCommentId);
    setShowDeleteConfirmModal(false);
  };

  /**
   * Closes the delete confirmation modal.
   */
  const handleCloseModal = () => {
    setShowDeleteConfirmModal(false);
    setDeleteCommentId(null);
  };

  /**
   * Handles the change event for the base code checkbox.
   */
  const handleBaseCodeCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewComment({
      ...newComment,
      isBaseCodeChecked: e.target.checked
    })
  };

  /**
   * Handles changes in the ReactQuill editor and manages the logic for user mentions.
   */
  const handleChange = useCallback((value: string) => {
    setNewComment(prev => ({
      ...prev,
      comment: value
    }));

    const quillEditor = quillRef.current?.getEditor();
    if (!quillEditor) return;

    // Get the Delta representation of the content
    const delta = quillEditor.getContents();

    const mentionedUserIds = new Set<number>();
    delta.ops.forEach(op => {
      if (op.attributes?.link) {
        const userId = Number(op.attributes.link);
        if (!isNaN(userId)) {
          mentionedUserIds.add(userId);
        }
      }
    });

    // Add or remove user IDs based on mention
    setSelectedUserIds(prevIds => {
      const newIds = new Set<number>(prevIds);

      // Add new user IDs
      mentionedUserIds.forEach(id => newIds.add(id));

      // Remove user IDs not mentioned
      prevIds.forEach(id => {
        if (!mentionedUserIds.has(id)) {
          newIds.delete(id);
        }
      });

      return Array.from(newIds);
    });

    // Filter user dropdown options which are not present in comment text as @user mention
    const mentionableUserList = userOptionsList.filter(user => !mentionedUserIds.has(user.id));

    // Get the cursor position within the editor
    const cursorPosition = quillEditor.getSelection()?.index ?? 0;
    // Get the text content from the start to the cursor position
    const textBeforeCursor = quillEditor.getText(0, cursorPosition);
    // Get the last character before the cursor
    const lastChar = textBeforeCursor.slice(-1);

    // Check if the last character is '@'
    if (lastChar === '@') {
      // Enable searching and set filtered options and menu position
      setSearchingEnabled(true);
      setUserSuggestionList(mentionableUserList);
      setPopoverPosition(cursorPosition, quillEditor);
    }
    // If '@' is present in the text before the cursor and searching is enabled
    else if (textBeforeCursor.includes('@') && searchingEnabled) {
      // Find the position of the last '@' symbol
      const atSymbolIndex = textBeforeCursor.lastIndexOf('@');
      // Get the text after the '@' symbol
      const searchText = textBeforeCursor.slice(atSymbolIndex + 1);

      setUserSuggestionList(
        mentionableUserList.filter(user =>
          user.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
      setPopoverPosition(cursorPosition, quillEditor);
    }
    // If there is no '@' or searching is not enabled
    else {
      // Close the mention dropdown
      setAnchorEl(null);
      setMenuPosition(null);
      setSearchingEnabled(false);
    }
  }, [userOptionsList, searchingEnabled]);

  const setPopoverPosition = (cursorPosition, quillEditor) => {
    const bounds = quillEditor.getBounds(cursorPosition);
    const editorElement = quillEditor.root as HTMLElement;
    const editorRect = editorElement.getBoundingClientRect();
    setMenuPosition({
      top: editorRect.top + bounds.top + window.scrollY - 120,
      left: editorRect.left + bounds.left + window.scrollX
    });
    setAnchorEl(editorElement);
  };

  /**
   * Handles user selection from the user dropdown list and inserts the mention into the Quill editor.
   */
  const handleUserSelect = (user: CommentTagUserOption) => {
    // Get the Quill editor instance from the ref
    const quillEditor = quillRef.current?.getEditor();
    if (quillEditor) {
      // Get the current cursor position in the editor
      const cursorPosition = quillEditor.getSelection()?.index ?? 0;
      // Get the text content from the start to the cursor position
      const textBeforeCursor = quillEditor.getText(0, cursorPosition);
      // Find the last occurrence of '@' in the text before the cursor
      const atSymbolIndex = textBeforeCursor.lastIndexOf('@');
      // Focus the Quill editor
      quillEditor.focus();

      // Remove the '@' symbol and the text after it until the cursor position
      quillEditor.deleteText(atSymbolIndex, cursorPosition - atSymbolIndex);

      // Insert the selected user's name as a link at the '@' symbol position
      quillEditor.insertText(atSymbolIndex, `@${user.name}`, {
        // Add a link attribute with the user's ID
        link: `${user.id}`,
        bold: true,
        color: '#106EBE'
      });
 
      // Move the cursor to the end of the inserted text
      quillEditor.setSelection(atSymbolIndex + user.name.length + 1, 0);

      // Clear the text formatting for the next input
      quillEditor.format('bold', false); // Reset bold
      quillEditor.format('color', false); // Reset color
    }

    // Reset the user dropdown options to the initial list
    setUserSuggestionList(userOptionsList);

    // Disable searching
    setSearchingEnabled(false);

    // Close the Popover
    setAnchorEl(null);
    setMenuPosition(null);
  };

  /**
   * Closes the user dropdown list
   */
  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
    setSearchingEnabled(false);
  };

  const removePTags = (html: string) => {
    // Replace the paragraph tags with line breaks
    html = html.replace(/<br>/g, '').replace(/<\/p><p>/g, '<br/>');

    // Remove <p> and </p> tags
    return html.replace(/<\/?p>/g, '');
  };

  /**
   * Handles the submission for adding / updating comment.
   */
  const handleSubmit = () => {
    const cleanedContent = removePTags(newComment?.comment);
    const updatedContent  = replaceAnchorTagsWithCurlyBraces(cleanedContent);
    const newCommentData = {
      id: newComment.id,
      comment: updatedContent,
      isBaseCodeChecked: newComment.isBaseCodeChecked,
      mentionedUserIds: selectedUserIds
    };
    onSubmit(newCommentData);
  };
  
  const replaceAnchorTagsWithCurlyBraces = (content: string) => {
    return content.replace(/<a[^>]+href="(\d+)"[^>]*>(?:<[^>]+>)?@?([^<]+)(?:<\/[^>]+>)?<\/a>/g, (match, userId, username) => {
      return `{${userId}#${username}}`;
    });
  };

  const replaceCurlyBracesWithAnchorTags = (content: string, isShowTooltip: boolean = false) => {
    return content.replace(/\{(\d+)#([^}]+)\}/g, (match, userId) => {
      const user = userOptionsList.find(u => u.id === Number(userId));

      // isShowTooltip is checking only for Display Comment box with default Tooltip
      if (isShowTooltip) {
        return `<a title="${user?.email}" style="color: rgb(16, 110, 190); cursor: pointer;"><strong>@${user?.name}</strong></a>`;  
      }

      return `<a href="${userId}" rel="noopener noreferrer" target="_blank" style="color: rgb(16, 110, 190);"><strong>@${user?.name}</strong></a>`;
    });
  };

  return (
    <>
      <ConfirmationModal
        isOpen={showDeleteConfirmModal}
        onClose={handleCloseModal}
        title={"Confirm Delete Comment"}
        message={"Are you sure you want to delete this comment?"}
        handleConfirmYes={handleDeleteConfirm}
        handleConfirmNo={handleCloseModal}
      />
      <Box style={{
        backgroundColor: `${CSSDefaults.headerBgColor}`,
        borderRadius: "4px",
        boxShadow: `5px 5px 8px 1px ${CSSDefaults.primaryColor}`,
        padding: "15px",
        margin: "20px 0",
        width: pageQueryParams ? "76%" : "100%",
        overflow: "hidden"
      }}>
        <Box sx={{
          borderRadius: "4px",
          padding: "15px",
          marginLeft: "10px"
        }}>
          <Typography variant="h5">
            Discussion
          </Typography>
          {sessionService.hasPermission(Permissions.ManageProductComments) &&
            <>
              <Box display="flex" gap={2} paddingTop={2}>
                <Avatar sx={{
                  bgcolor: `${CSSDefaults.primaryColor}`,
                  height: 50,
                  width: 50,
                  fontSize: 25
                }}>
                  {renderUserInitial(currentUser.name)}
                </Avatar>
                <ReactQuill
                  ref={quillRef}
                  value={newComment?.comment || ""}
                  onChange={handleChange}
                  placeholder="Add a comment"
                  modules={{
                    toolbar: false,
                  }}
                  formats={['link', 'bold', 'color']}
                  className="custom-quill"
                />
              </Box>
              <Popover
                open={Boolean(anchorEl)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
                onClose={handleClose}
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                sx={{
                  maxHeight: '400px',
                  left: '50px',
                }}
              >
                {userSuggestionList.length ? (
                  <List>
                    {userSuggestionList.map(user => (
                      <ListItemButton
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        sx={{
                          borderBottom: '1px solid #ddd',
                          '&:last-child': { borderBottom: 'none' }
                        }}>
                        <ListItemText primary={user.name} secondary={user.email} />
                      </ListItemButton>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body2" sx={{ padding: 1 }}>
                    No users found
                  </Typography>
                )}
              </Popover>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft={8} gap={1}>
                <FormControlLabel
                  sx={{ '& .MuiTypography-root': { fontSize: '15px' } }}
                  control={
                    <Checkbox
                      checked={newComment?.isBaseCodeChecked}
                      onChange={(e) => handleBaseCodeCheckBoxChange(e)}
                      disabled={!(editItem?.baseCode || editItem?.pdCode)}
                    />
                  }
                  label={"This is a Base Code level comment"}
                />
                <Box display="flex" gap={1} marginTop={"5px"}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setNewComment(initialNewComment)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!(newComment?.comment).trim()}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </>}
        </Box>
        {commentList?.length > 0 ?
          commentList.map((cl) => (
            <Box key={cl.id} display="flex" marginTop={2} marginLeft={1}>
              <Avatar sx={{
                bgcolor: `${CSSDefaults.primaryColor}`,
                height: 50,
                width: 50,
                fontSize: 25
              }}>
                {renderUserInitial(cl.commentByDisplayText)}
              </Avatar>
              <Box className="product-comment-list-content">
                <div>
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {cl.commentByDisplayText}&nbsp;
                  </span>
                  <span style={{ color: `${CSSDefaults.lightGrey}`, fontSize: "13px" }}>
                    commented {cl.updatedDate ? renderFormattedDate(cl.updatedDate) : renderFormattedDate(cl.createdDate)}
                  </span>
                </div>
                <div style={{ marginTop: "5px" }}>
                  <span dangerouslySetInnerHTML={{ __html: replaceCurlyBracesWithAnchorTags(cl.comment, true) }} />
                  {(currentUser.id === cl.commentBy) &&
                    (sessionService.hasPermission(Permissions.ManageProductComments)) &&
                    <span className="comment-content-hover-icon">
                      <IconButton onClick={() =>
                        setNewComment({
                          id: cl.id,
                          comment: replaceCurlyBracesWithAnchorTags(cl.comment),
                          isBaseCodeChecked: !!((cl.baseCode || cl.pdBaseCode))
                        })}>
                        <EditIcon style={{ cursor: "pointer" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setShowDeleteConfirmModal(true);
                          setDeleteCommentId(cl.id);
                        }}>
                        <DeleteIcon style={{ cursor: "pointer" }} />
                      </IconButton>
                    </span>}
                </div>
              </Box>
            </Box>
          ))
          : (
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Typography variant="h6" component="div" fontWeight="bold">
                There are no comments yet
              </Typography>
            </Box>
          )
        }
      </Box>
    </>
  )
}

export default ProductComments;