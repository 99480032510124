import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttachmentListRequest } from "../models/Attachments/AttachmentListRequest";
import { SortDirection } from "../models/Enum";

interface AttachmentsState {
    attachmentListRequest: AttachmentListRequest;
};

const initialAttachmentListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "prjCode",
    sortDirection: SortDirection.DESC,
};

const initialState: AttachmentsState = {
    attachmentListRequest: initialAttachmentListRequest,
};

const attachments = createSlice({
    name: 'attachments',
    initialState,
    reducers: {
      updateAttachmentListRequest(state, action: PayloadAction<AttachmentListRequest>) {
        state.attachmentListRequest = action.payload;
      },
    },
  });
  
  export const { updateAttachmentListRequest } = attachments.actions;
  
  export default attachments.reducer;