import { Autocomplete, Box, Button, Card, CardActions,CardContent,TextField } from "@mui/material";
import { AttachmentSelectedFilters } from "../../models/Attachments/AttachmentListRequest";
import { useAppDispatch, useAppSelector } from "../../store";
import api from "../../services/ApiService";
import { ApiResponse } from "../../models/ApiResponse";
import { AttachmentFilterOptions } from "../../models/Attachments/AttachmentFilterOptions";
import { useCallback, useEffect, useState } from "react";
import { updateAttachmentViewSelectedFilterOptions, updateAttachmentViewSelectedFilterValues } from "../../reducers/attachmentFiltersReducer";
import { FIELD_NAMES } from "../Products/EditItem/Constants";
import { PrjStageWithStatus } from "../../models/PrjStage";
import { DATE_FORMAT } from "../../utils/constants";
import moment from "moment";

const AttachmentFilter = ({onApplyFilter}) => {
    const REFERENCE_FIELDS = [
        "marketingDesigner",
        "attachmentTypeId",
        "stage",
        "status",
        "category",
        "function",
        "brand",
      ];

    const initialSelectedFilters: AttachmentSelectedFilters = {};
    const [filterOptions, setFilterOptions] = useState<AttachmentFilterOptions>({});
    const [statusDisabled, setStatusDisabled] = useState<boolean>(false);
    
     const dispatch = useAppDispatch();

    const attachmentListFilterOptionsSelected = useAppSelector((state) => state.attachmentFilters.attachmentListView.selectedFilterOptions);
    const attachmentListFilterValuesSelected = useAppSelector((state) => state.attachmentFilters.attachmentListView.selectedFilterValues);


    useEffect(()=>{
        getAllFilterOptions();
    },[])

    const getAllFilterOptions = async () => {
        try {
          const dropDownSources = await api.get<ApiResponse<AttachmentFilterOptions>>(`/Item/filter-options`);
    
          if (dropDownSources?.isSuccess) {
            const stageStatusResponse = api
              .get<ApiResponse<PrjStageWithStatus[]>>(`/PickList/project-stage-stauses-list`)
              .then((response) => {
                if (response?.isSuccess) {
                  return response;
                }
              })
              .catch((error) => {
                console.error("Exception from stage status list api", error);
              });
    
            const [
              stageStatus,
            ] = await Promise.all([
              stageStatusResponse,
            ]);
    
            dropDownSources.data.stageStatus = (stageStatus as ApiResponse<PrjStageWithStatus[]>).data;
            setFilterOptions(dropDownSources.data);
          }
        } catch (error) {
          console.error("Exception from filter options", error);
        }
    };

    const prepareStatusDropDownOptionsBasedOnStage = useCallback((stageId: number) => {
        if (stageId && filterOptions?.stageStatus?.length) {
            const statusDropDownOptions = filterOptions.stageStatus
                .find(stage => stage.id === stageId)?.statuses.map(s => ({
                    id: s.id,
                    description: s.description,
                })) || [];

            const currentStatuses = filterOptions.projectStatuses || [];
            const statusChanged = JSON.stringify(currentStatuses) !== JSON.stringify(statusDropDownOptions);

            if (statusChanged) {
                setFilterOptions({
                    ...filterOptions,
                    projectStatuses: statusDropDownOptions
                });
            }
        }
    }, [filterOptions, setFilterOptions]);

    const setStatusDropDownBasedOnStageValue = useCallback(() => {
        if(attachmentListFilterValuesSelected?.stage) {
            prepareStatusDropDownOptionsBasedOnStage(attachmentListFilterValuesSelected?.stage);
            setStatusDisabled(false);
        }
        else if(!attachmentListFilterValuesSelected?.stage) {
            setStatusDisabled(true);
        }
    }, [
        attachmentListFilterValuesSelected,
        prepareStatusDropDownOptionsBasedOnStage,
    ]);

    useEffect(() => {
        setStatusDropDownBasedOnStageValue();
    }, [
        attachmentListFilterValuesSelected,
        setStatusDropDownBasedOnStageValue
    ]);

    const renderFilterOptionsSelected = (fieldName: string) => {
        if(fieldName === "prjCode") {
            return attachmentListFilterOptionsSelected[fieldName]
            ? attachmentListFilterOptionsSelected[fieldName]
            : ""
        }else{
            return attachmentListFilterOptionsSelected[fieldName]
            ? attachmentListFilterOptionsSelected[fieldName]
            : null
        }
    };

    const handleFilterChange = (fieldName, selectedItem) => {
        if(!selectedItem){
            if (fieldName === FIELD_NAMES.stage) {
                dispatch(updateAttachmentViewSelectedFilterValues({
                    ...attachmentListFilterValuesSelected,
                    [fieldName]: undefined,
                    status: undefined,
                  }));
            
                dispatch(updateAttachmentViewSelectedFilterOptions({
                ...attachmentListFilterOptionsSelected,
                [fieldName]: undefined,
                status: undefined,
                }));
            }
            else {
                dispatch(updateAttachmentViewSelectedFilterValues({
                    ...attachmentListFilterValuesSelected,
                    [fieldName]: undefined,
                  }));
            
                dispatch(updateAttachmentViewSelectedFilterOptions({
                    ...attachmentListFilterOptionsSelected,
                    [fieldName]: undefined,
                  }));
            }
        }
        else {
            const selectedValue =
                typeof selectedItem === "string"
                    ? selectedItem
                    : REFERENCE_FIELDS.includes(fieldName)
                        ? selectedItem.id
                        : selectedItem.name;

                if (fieldName === FIELD_NAMES.stage) {
                    dispatch(updateAttachmentViewSelectedFilterValues({
                        ...attachmentListFilterValuesSelected,
                        [fieldName]: selectedValue,
                        status: undefined,
                    }));
                    //store the {id, name} when assigning autocomplete
                    dispatch(updateAttachmentViewSelectedFilterOptions({
                        ...attachmentListFilterOptionsSelected,
                        [fieldName]: selectedItem,
                        status: undefined,
                    }));
                }
                else {
                    dispatch(updateAttachmentViewSelectedFilterValues({
                        ...attachmentListFilterValuesSelected,
                        [fieldName]: selectedValue,
                    }));
                    //store the {id, name} when assigning autocomplete
                    dispatch(updateAttachmentViewSelectedFilterOptions({
                        ...attachmentListFilterOptionsSelected,
                        [fieldName]: selectedItem,
                    }));
                }
        }
    };

    const filterInputStyle = {
        flex: "0 0 calc(20% - 10px)",
        margin: "8px 5px",
        width: "206px",
        "& .MuiOutlinedInput-root": {
          padding: 0,
          input: {
            paddingBottom: 0,
          },
        },
    };

    const isStatusFieldDisabled = (fieldName: string) => {
        return fieldName === FIELD_NAMES.status && statusDisabled
    };

    const renderAutocomplete = (
        fieldName,
        label,
        optionList: Array<any> | undefined,
        optionLabelGetter: (option) => string = null
    ) => (
        <Autocomplete
            sx={filterInputStyle}
            value={
                renderFilterOptionsSelected(fieldName)
            }
            onChange={(event, newValue) => handleFilterChange(fieldName, newValue)}
            options={optionList ? optionList : []}
            getOptionLabel={(option) =>
                optionLabelGetter ? optionLabelGetter(option) : option.name || option.description || option
            }
            disabled={isStatusFieldDisabled(fieldName)}
            style={{
                backgroundColor: isStatusFieldDisabled(fieldName) ? "#EBEBE4" : "#FFFFFF"
            }}
            renderInput={(params) => (
                <TextField
                    key={params.id}
                    {...params}
                    label={label}
                    variant="outlined"
                />
            )}
        />
    );

    const handleResetFilterClick = () => {
        dispatch(updateAttachmentViewSelectedFilterOptions({}));
        dispatch(updateAttachmentViewSelectedFilterValues(initialSelectedFilters));
        onApplyFilter({});
    };

    return(
        <Card sx={{ marginBottom: 1 }}>
            <span style={{ padding: "0 20px", fontSize: "16px", fontWeight: 700 }}>Filters</span>
            <CardActions >
                <CardContent style={{ paddingBottom:  2 }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        {renderAutocomplete(
                            "brand",
                            "Brand Collection",
                            filterOptions.brands
                        )}
                        {renderAutocomplete(
                            "stage",
                            "Stage",
                            filterOptions.projectStages
                        )}
                        {renderAutocomplete(
                            "status",
                            "Status",
                            filterOptions.projectStatuses
                        )}
                        {renderAutocomplete(
                            "stockCode",
                            "Stock Code",
                            filterOptions.stockCodes
                        )}
                        {renderAutocomplete(
                            "baseCode",
                            "Base Code",
                            filterOptions.baseCodes
                        )}
                        {renderAutocomplete(
                            "pdCode",
                            "PD BaseCode",
                            filterOptions.pdCodes
                        )}
                        {renderAutocomplete(
                            "seriesName",
                            "Series Name",
                            filterOptions.seriesNames
                        )}
                        {renderAutocomplete(
                            "pdFamilyName",
                            "PD Family",
                            filterOptions.pdFamilyNames
                        )}
                        {renderAutocomplete(
                            "category",
                            "Category",
                            filterOptions.productcategories
                        )}
                        {renderAutocomplete(
                            "function",
                            "Function",
                            filterOptions.productFunctions
                        )}
                        {renderAutocomplete(
                            "marketingDesigner",
                            "Designer",
                            filterOptions.productDesignerMkts
                        )}
                        {renderAutocomplete(
                            "attachmentTypeId",
                            "Attachment Type",
                            filterOptions.attachmentTypes
                        )}
                        {renderAutocomplete(
                            "slIntroDate",
                            "SL Intro Date",
                            filterOptions.productIntroDatess,
                            (option) => moment(option).format(DATE_FORMAT)
                        )}
                        <TextField
                            sx={filterInputStyle}
                            className="hide-input-arrow"
                            label="Search Product Id"
                            variant="outlined"
                            inputProps={{ type: "number" }}
                            value={
                                renderFilterOptionsSelected("prjCode")
                            }
                            onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
                            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
                            onChange={(event) =>
                                handleFilterChange("prjCode", (event.target.value).trim())
                            }
                        />
                    </Box>
                        <Button
                            variant="contained"
                            style={{ margin: "8px 5px", width: "135px", maxHeight: "37px" }}
                            onClick = {() => onApplyFilter(attachmentListFilterValuesSelected)}
                        >
                            Search
                        </Button>
                        <Button
                            variant="outlined"
                            style={{ margin: "8px 5px", width: "135px", maxHeight: "37px" }}
                            onClick={handleResetFilterClick}
                        >
                            Reset
                        </Button>
                </CardContent>
            </CardActions>
        </Card>
    )
};

export default AttachmentFilter;