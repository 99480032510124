export enum Permissions {
  ManageAppSettings = 1,
  CreateUser = 2,
  UpdateUser = 3,
  ViewUsers = 4,
  ManageUserRoles = 5,
  CreateProduct = 6,
  UpdateProduct = 7,
  ViewProducts = 8,
  ManageProductAssignments = 9,
  ManageAttachments = 10,
  ManageTags = 11,
  BulkCreateProducts = 12,
  BulkUpdateProducts = 13,
  ExecutiveDashboard = 14,
  ManageProductComments = 15,
  ManageStageAdvancementCheckList = 16,
  DeleteProject = 17,
  ManageAssignments = 18,
  ManageBOM = 19,
  ViewAppSetting = 20,
  ViewQuotes = 21,
}

export const PermissionsFriendlyNames: { [key: number]: string } = {
  [Permissions.ManageAppSettings]: 'Manage App Settings',
  [Permissions.CreateUser]: 'Create User',
  [Permissions.UpdateUser]: 'Update User',
  [Permissions.ViewUsers]: 'View Users',
  [Permissions.ManageUserRoles]: 'Manage User Roles',
  [Permissions.CreateProduct]: 'Create Product',
  [Permissions.UpdateProduct]: 'Update Product',
  [Permissions.ViewProducts]: 'View Products',
  [Permissions.ManageProductAssignments]: 'Manage Product Assignments',
  [Permissions.ManageAttachments]: 'Manage Attachments',
  [Permissions.ManageTags]: 'Manage Tags',
  [Permissions.BulkCreateProducts]: 'Bulk Create Products',
  [Permissions.BulkUpdateProducts]: 'Bulk Update Products',
  [Permissions.ExecutiveDashboard]: 'Executive Dashboard',
  [Permissions.ManageProductComments]: 'Manage Product Comments',
  [Permissions.ManageStageAdvancementCheckList]: 'Manage Stage Advancement CheckList',
  [Permissions.DeleteProject]: 'Delete Project',
  [Permissions.ManageAssignments]: 'Manage Assignments',
  [Permissions.ManageBOM]: 'Manage BOM',
  [Permissions.ViewAppSetting]: 'App Setting Read Only',
  [Permissions.ViewQuotes]: 'View Quotes',
};

export enum Roles {
  Administrator = 20,
  Manager = 22,
  ProjectCoordinator = 24,
  User = 25,
}
export enum SortDirection {
  ASC,
  DESC,
}
export enum NotificationType
{
    NewProduct = 1,
    StageStatusTagUpdate = 2,
    AttachmentUpdate = 3,
    AssignmentUpdate = 4,
    ProductUpdate = 7,
    ProcessExecutiveReview = 5,
    ProductCommentUpdate = 6,
    ProductDelete = 11,
    AttachmentAdd = 8,
    AttachmentRemove = 9,
    NewProductComment = 10
}

// export enum Tags {
//   NeedsExecutiveReview = 17
// }

export enum SpecialTags {
  ReviewRequired = 'ReviewRequired',
  ReviewApproved = 'ReviewApproved',
  ReviewRejected = 'ReviewRejected'
}