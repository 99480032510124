import { Box } from "@mui/material";
import { DataGrid, GridSortModel} from "@mui/x-data-grid";
import { useState } from "react";
import SessionService from "../../services/SessionService";
import { Permissions } from "../../models/Enum";
import CustomToolbar from "../../components/CustomToolBar";
const RoleListing = ({ userListData, rolesGridSchema }) => {
  const sessionService = SessionService.getInstance();
  const manageRolePermission = sessionService.hasPermission(Permissions.ManageUserRoles)

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const exportData = userListData.map((row) => {
    const rowData = {};
    rolesGridSchema.forEach((column) => {
      rowData[column.field] = row[column.field];
    });
    return rowData;
  });
  return (
    <>
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          rows={userListData}
          columns={rolesGridSchema}
          slots={{ toolbar: CustomToolbar }}
          // slotProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //   },
          // }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          autoHeight
          sortingOrder={["asc", "desc"]}
          sortModel={sortModel}
          onSortModelChange={(sort) => setSortModel(sort)}
          disableRowSelectionOnClick={true}
          getRowHeight={() => 'auto'}
          columnVisibilityModel={{
            actions: manageRolePermission,
          }}
        />
      </Box>
    </>
  );
};
export default RoleListing;
