import { useEffect, useMemo, useState } from "react";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { Box, Button, IconButton} from "@mui/material";
import { toast } from "react-toastify";
import { SessionDetails } from "../../../models/SessionDetails";
import StockCodeModal from "./StockCodeModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableContainer,
  useMaterialReactTable,
} from "material-react-table";
import { StockCodeHierarchy } from "../../../models/StockCodeHierarchy";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function () {
  const [stockCodeHierarchiesList, setStockCodeHierarchiesList] = useState<
    StockCodeHierarchy[]
  >([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const stockCodeHierarchyGridCollumns = useMemo<
    MRT_ColumnDef<StockCodeHierarchy>[]
  >(
    () => [
      { accessorKey: "id", header: "Id", width: 100 },
      { accessorKey: "rank", header: "Rank", width: 100 },
      {
        accessorKey: "attachTypeDisplayText",
        header: "Attachment Type",
        width: 350,
      },
    ],
    []
  );

  
  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const table = useMaterialReactTable({
    columns: stockCodeHierarchyGridCollumns,
    data: stockCodeHierarchiesList,
    enablePagination: false,
    enableRowActions: manageAppSetting,
    positionActionsColumn: "last",
    enableRowOrdering: manageAppSetting,
    enableSorting: false,
    enableFilters: false,
    enableColumnActions: false,
    renderRowActions: ({ row }) => (
      <Box style={{ width: "150px" }}>
        <IconButton onClick={() => showDeleteModalOpen(row.original)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: async () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          stockCodeHierarchiesList.splice(
            (hoveredRow as MRT_Row<StockCodeHierarchy>).index,
            0,
            stockCodeHierarchiesList.splice(draggingRow.index, 1)[0]
          );
          setStockCodeHierarchiesList([...stockCodeHierarchiesList]);
          await updateRanks(stockCodeHierarchiesList);
        }
      },
    }),
  });

  const updateRanks = async (reOrderedList) => {
    let rank = 1;
    reOrderedList.forEach((baseCodeItem) => {
      baseCodeItem.rank = rank;
      rank++;
    });

    const resp = await api.put<ApiResponse<boolean>>(
      "/Picklist/stockcode-hierarchies/update-ranks",
      { scImageHierarchies: reOrderedList }
    );
    if (resp?.isSuccess) {
      toast.success("Rank updated successfully");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getStockCodeHierarchyRepsonse = await api.get<
      ApiResponse<StockCodeHierarchy[]>
    >("/Picklist/stockcode-hierarchies");
    setStockCodeHierarchiesList(getStockCodeHierarchyRepsonse?.data || []);
  };

  const showDeleteModalOpen = (row: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const deleteSubmit = () => {
    api
      .delete<ApiResponse<SessionDetails>>(
        `/Picklist/stockcode-hierarchy/${formik.values.id}`
      )
      .then(async (response: any) => {
        if (response.isSuccess) {
          setDeleteModalOpen(false);
          formik.resetForm();
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {});
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      rank: 0,
      attachType: "",
      isActive: true,
    },
    validationSchema: Yup.object({
      rank: Yup.number()
        .required("Rank is Required")
        .min(1, "Rank must be greater than 0")
        .test(
          "check-rank-duplicate",
          "Rank already exists",
          function async(value) {
            return new Promise((resolve) => {
              const isRankDuplicate = stockCodeHierarchiesList.some(
                (item) => item.rank === value && item.id !== formik.values.id
              );
              isRankDuplicate ? resolve(false) : resolve(true);
            });
          }
        ),
      attachType: Yup.string()
        .required("Attachment Type is Required")
        .test(
          "check-attach-type-duplicate",
          "Attachment type already exists",
          function async(value) {
            return new Promise((resolve) => {
              const isAttachDuplicate = stockCodeHierarchiesList.some(
                (item) =>
                  item.attachType &&
                  item.attachType == value &&
                  item.id != formik.values.id
              );
              isAttachDuplicate ? resolve(false) : resolve(true);
            });
          }
        ),
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/stockcode-hierarchy", {
            id: values.id,
            rank: values.rank,
            attachType: values.attachType,
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/stockcode-hierarchy", {
            rank: values.rank,
            attachType: values.attachType,
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button
          variant="contained"
          onClick={() => {
            setShowAddUpdateModal(true);
            setDisableSubmit(false);
          }}
        >
          Include Attachment Type
        </Button>}
        {showAddUpdateModal && (
          <StockCodeModal
            isOpen={showAddUpdateModal}
            onClose={handleCloseModal}
            formik={formik}
            isButtonDisabled={disableSubmit}
          />
        )}
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Stockcode Rank"}
        />
      </div>
      <MRT_TableContainer table={table} style={{ marginTop: "10px" }} />
    </>
  );
}
