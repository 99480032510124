import { Button, Chip, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { AttachmentType } from "../../models/AttachmentType";
import api from "../../services/ApiService";
import { ApiResponse } from "../../models/ApiResponse";
import Loader from "../../components/Loader";

const ExtractAttachmentsModal = ({isOpen, onClose, selectedItems, allSelected, onSubmit, disableExtractAttachmentSubmit, isLoading}) => {
    const [attachmentTypesList, setAttachmentTypesList] = useState<AttachmentType[]>([])
    const [selectedAttachmentTypes,setSelectedAttachmentTypes] = useState([]);
    const [attachmentLevel, setAttachmentLevel] = useState('All');
    
    const stockCodes = allSelected ? [] : selectedItems.reduce((acc, row) => {
        if (!!row.stockCode && !acc.includes(row.stockCode)) {
          acc.push(row.stockCode);
        }
        return acc;
      }, []);
      
      const baseCodes = allSelected ? [] : selectedItems.reduce((acc, row) => {
        if (!!row.baseCode && !acc.includes(row.baseCode)) {
          acc.push(row.baseCode);
        }
        return acc;
      }, []);
      
    const isSubmitDisabled = attachmentLevel === '' || selectedAttachmentTypes.length === 0 || disableExtractAttachmentSubmit;

    const itemIds = selectedItems.map(row => row.id);

    const levelNames = [
        {value: 'All', label:'All'},
        {value: 'SC', label:'Stock Code'},
        {value: 'BC', label:'Base Code'}, 
    ];

    useEffect(() => {
        if(isOpen){
            setSelectedAttachmentTypes([]);
        }
        getAttachmentFetchData();
    },[isOpen]);

    const getAttachmentFetchData = async () => {
        try{
            const response = await api.get<ApiResponse<AttachmentType[]>>('/Picklist/attachment-types');
            setAttachmentTypesList(response?.data || []);
        } catch(err) {}
    };

    
    const renderCheckBox = () => {
        return attachmentTypesList.filter(attatchmentType => attatchmentType.isActive === true 
            && (attachmentLevel === 'All' || attatchmentType.lvl === attachmentLevel) )
        .map((attachmentType) => (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selectedAttachmentTypes.some(type => type === attachmentType.id )}
                        onChange={() => {
                            if (selectedAttachmentTypes.includes(attachmentType.id)) {
                                setSelectedAttachmentTypes(prev => prev.filter(type => type !== attachmentType.id));
                            }  else {
                                setSelectedAttachmentTypes(prev => [...prev, attachmentType.id]);
                            } 
                        }}
                    />
                }
                key={attachmentType.id}
                label={attachmentType.attachType}                                
            />
        ))
    };

    const renderChips = (codes) => {
        if (allSelected) {
            return <Chip label={`Exporting for all selected records`} />;
          }
        else if (codes.length <= 50) {
          return codes.map((code) => <Chip sx = {{margin: "2px"}} label={code} key={code} />);
        } else {
          return <Chip label={`COUNT ${codes.length} stock codes selected`} />;
        }
      };

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth
        sx={{
            "& .MuiDialog-container": {
              '& .MuiPaper-root': {
                maxWidth: '60%'
              },
            },
          }}>
        <Loader isLoading={isLoading} />
        <DialogActions>
          <Button onClick={onClose}>x</Button>
        </DialogActions>
        <DialogContent>
          <h4>Select Attachment Types</h4>
          <div style={{ marginBottom: "20px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "20px", flex: 1 }}>
                <h3>Stock Codes:</h3>
                <div>
                  {allSelected || stockCodes.length > 0 ? (
                    renderChips(stockCodes)
                  ) : (
                    <span>No stock codes selected</span>
                  )}
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <h3>Base Codes:</h3>
                <div>
                  {allSelected || baseCodes.length > 0 ? (
                    renderChips(baseCodes)
                  ) : (
                    <span>No base codes selected</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <FormControl fullWidth>
            <InputLabel>Attachment Type Level</InputLabel>
            <Select
              value={attachmentLevel}
              label="Attachment Level"
              required
              onChange={(event) => setAttachmentLevel(event.target.value)}
            >
              {levelNames.map((levelName) => (
                <MenuItem key={levelName.value} value={levelName.value}>
                  {levelName.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflowY: "auto",
                maxHeight: "300px"
              }}
            >
              {" "}
              {attachmentLevel === "" ? null : renderCheckBox()}
            </div>
          </FormGroup>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => onSubmit(itemIds, selectedAttachmentTypes)}
              disabled={isSubmitDisabled}
            >
              Extract
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
};

export default ExtractAttachmentsModal;