import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { ApiResponse } from "../models/ApiResponse";
import { User } from "../models/User";
import api from "../services/ApiService";
import { KeyValues } from "../models/Items/FilterOptions";
import { CSSDefaults } from "../models/GlobalConstants";
import ConfirmationModal from "./ConfirmationModal";
import SessionService from '../services/SessionService';
import { Permissions } from '../models/Enum';

type UserAssignmentsProps = {
  selectedUserAssignments: UserAssignmentFieldValue[];
  setSelectedUserAssignments: (selectedUserAssignments: UserAssignmentFieldValue[]) => void;
  viewMode: string;
  assignmentRoleDropdownOptions: KeyValues[];
  userDropdownOptions: UserDropdownOptions[];
};

export interface UserAssignmentFieldValue {
  [key: string]: string | number;
};

export interface UserDropdownOptions {
  id: number;
  name: string;
};

type UserDropdownValue = {
  index: number;
  field: string;
  value: number;
};

const UserAssignments = (props: UserAssignmentsProps) => {

  const {
    selectedUserAssignments,
    setSelectedUserAssignments,
    viewMode,
    assignmentRoleDropdownOptions,
    userDropdownOptions
  } = props;

  const [isAddNewRowDisabled, setIsAddNewRowDisabled] = useState<boolean>(false);
  const [showDropdownChangeConfirmationModal, setShowDropdownChangeConfirmationModal] = useState(false);
  const [newDropdownValue, setNewDropdownValue] = useState<UserDropdownValue | null>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState<number | null>(null);

  const sessionService = SessionService.getInstance();

  // This is used to disable add new row button if assignment role dropdown value is empty
  useEffect(() => {
    if (selectedUserAssignments) {
      const containsEmptyString = selectedUserAssignments.find(obj => obj.assignmentId === "");
      setIsAddNewRowDisabled(!!containsEmptyString);
    }
  }, [selectedUserAssignments]);

  // This function is used to add new row
  const handleAddNewRow = () => {
    setSelectedUserAssignments([...selectedUserAssignments, { userId: "", assignmentId: "", id: 0 }]);
  };

  /**
   * This function is used to remove user assignment row
   * @param index - Index value of user assignment row array
   */
  const handleUserAssignmentRemove = (index: number) => {
    // Check if user assignment dropdown(s) value is not empty for displaying confirmation dialog
    const checkIsValueNotEmpty = selectedUserAssignments
      .filter((_, i) => i === index)
      .some((ua) => ua.userId !== "" && ua.assignmentId !== "");

    // Check if view mode is EditProduct and user assignment dropdown(s) contains selected value from dropdown options
    if (viewMode === "EditProduct" && checkIsValueNotEmpty) {
      setShowDeleteConfirmationModal(true);
      setDeleteRowIndex(index);
    }
    // else remove user assignment row with updating state
    else {
      const updateSelectedUserAssignments = selectedUserAssignments.filter((_, i) => i !== index);
      setSelectedUserAssignments(updateSelectedUserAssignments);
    }
  };

  /**
   * This function is used to change the user and assignment role dropdowns value
   * @param index - Index value of user assignment row array
   * @param field - Field name
   * @param value - Dropdown selection value
   */
  const handleDropdownChange = (index: number, field: string, value: number) => {
    // Check if view mode is EditProduct and user assignment dropdown(s) contains selected value from dropdown options
    if (viewMode === "EditProduct" && selectedUserAssignments[index][field]) {
      setShowDropdownChangeConfirmationModal(true);
      setNewDropdownValue({
        index: index,
        field: field,
        value: value
      });
    }
    // else change dropdown selection with updating the state value
    else {
      setNewDropdownValue(null);
      const updateSelectedUserAssignments = [...selectedUserAssignments];
      updateSelectedUserAssignments[index][field] = value;
      setSelectedUserAssignments(updateSelectedUserAssignments);
    }
  };

  // This function is used to handle dropdown selection change confirmation modal for Edit Product
  const handleDropdownChangeConfirm = () => {
    const { index, field, value } = newDropdownValue;
    const updateSelectedUserAssignments = [...selectedUserAssignments];
    updateSelectedUserAssignments[index][field] = value;
    setSelectedUserAssignments(updateSelectedUserAssignments);
    handleDropdownChangeModalClose();
  };

  // This function is used to handle delete user assignment row - confirmation modal for Edit Product
  const handleDeleteConfirm = () => {
    const updateSelectedUserAssignments = selectedUserAssignments.filter((_, i) => i !== deleteRowIndex);
    setSelectedUserAssignments(updateSelectedUserAssignments);
    setDeleteRowIndex(null);
    handleDeleteModalClose();
  };

  // This function is used to handle dropdown change confirmation modal close for Edit Product
  const handleDropdownChangeModalClose = () => {
    setShowDropdownChangeConfirmationModal(false);
  };

  // This function is used to handle delete confirmation modal close for Edit Product
  const handleDeleteModalClose = () => {
    setShowDeleteConfirmationModal(false);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={showDropdownChangeConfirmationModal}
        onClose={handleDropdownChangeModalClose}
        title={"Confirm User Assignment Modification"}
        message={"Are you sure you want to modify this user assignment?"}
        handleConfirmYes={handleDropdownChangeConfirm}
        handleConfirmNo={handleDropdownChangeModalClose}
      />
      <ConfirmationModal
        isOpen={showDeleteConfirmationModal}
        onClose={handleDeleteModalClose}
        title={"Confirm User Assignment Deletion"}
        message={"Are you sure you want to delete this user assignment?"}
        handleConfirmYes={handleDeleteConfirm}
        handleConfirmNo={handleDeleteModalClose}
      />
      <div style={{ marginTop: "20px" }}>
        <Box
          sx={{
            border: "1px solid",
            borderRadius: "4px",
            padding: "15px",
            margin: "20px 0",
            ul: {
              listStyle: "none",
              display: "flex",
              flexDirection: "row",
              li: {
                padding: 1,
                border: `solid 1px ${CSSDefaults.lightColor}`,
                margin: 1,
                borderRadius: "2px",
              },
            },
          }}
        >
          <Typography variant="h6" sx={{ marginLeft: "5px", marginBottom: "10px" }}>Product Assignments</Typography>
          {selectedUserAssignments.map((field, index) => (
            <div key={index + 1} style={{ display: "flex", alignItems: "center" }}>
              {Object.keys(field).filter((key) => key !== "id").map((fieldKey, i) => (
                <FormControl key={i + 1} style={{ margin: "5px 5px", width: "32%" }}>
                  <Select
                    displayEmpty={true}
                    value={selectedUserAssignments[index][fieldKey] || ""}
                    onChange={(e) => handleDropdownChange(index, fieldKey, e.target.value as number)}
                    disabled={sessionService.hasPermission(Permissions.ManageProductAssignments) ? 
                      (fieldKey == "assignmentId" && !selectedUserAssignments[index]["userId"]): true}
                    error={fieldKey == "assignmentId" && !selectedUserAssignments[index]["assignmentId"]}
                  >
                    <MenuItem value="" disabled>
                      {fieldKey == "userId" ? "- Select User -" : "- Select Assignment -"}
                    </MenuItem>
                    {(fieldKey == "userId" ?
                      userDropdownOptions :
                      assignmentRoleDropdownOptions).map((option) => (
                        <MenuItem key={option.id} value={option.id} disabled={fieldKey == "userId" && selectedUserAssignments.some(ua => ua.userId === option.id)}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ))}
              {index > 0 && (
                sessionService.hasPermission(Permissions.ManageProductAssignments)
                 && <ClearIcon sx={{ cursor: "pointer", marginLeft: "5px" }} onClick={() => handleUserAssignmentRemove(index)}></ClearIcon>
              )}
            </div>
          ))}

          {sessionService.hasPermission(Permissions.ManageProductAssignments) 
          && <Button sx={{ marginTop: "5px" }} onClick={handleAddNewRow} disabled={isAddNewRowDisabled}>
            <AddIcon />
            Add New Row
          </Button>}
        </Box>
      </div>
    </>
  );
};

export default UserAssignments;
