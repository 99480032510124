import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = (props: any) => {
  return (
    <>
      {props.isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0, left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(255, 255, 255, 0.5)",
            textAlign: "center",
            zIndex: 999
          }}
        >
          <CircularProgress color="primary" style={{width: "100px", height: "100px"}} />
        </Box>
      )}
    </>
  );
};

export default Loader;
