import { useEffect, useState } from "react";
import AttachmentFilter from "../AttachmentFilter"
import { SortDirection } from "../../../models/Enum";
import api from "../../../services/ApiService";
import { AttachmentBasicDetail } from "../../../models/Attachments/AttachmentBasicDetail";
import { ApiResponse } from "../../../models/ApiResponse";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarQuickFilter } from "@mui/x-data-grid";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateAttachmentListRequest } from "../../../reducers/attachmentsReducer";
import { AttachmentSelectedFilters } from "../../../models/Attachments/AttachmentListRequest";
import imageNotAvailable from "../../../no-image.png";
import DownloadIcon from '@mui/icons-material/Download';
import { Box, CardMedia, IconButton } from "@mui/material";
import ImageViewModal, { ImageDetail } from "./ImageViewModal";
import GetFileType from "../../../components/GetFileType";
import GetIconByFileType from "../../../components/GetIconByFileType";
import { toast } from "react-toastify";
import PDFViewModal, { PDFDetail } from "./PDFViewModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { THUMBNAIL_IMAGE_API_URL } from "../../Products/EditItem/Constants";

export interface AttachmentListResponse {
    attachments?: AttachmentBasicDetail[]
    totalCount?: number
};

const AttachmentList = () => {

    const dispatch = useAppDispatch();
    const attachmentListRequestState = useAppSelector((state) => state.attachments.attachmentListRequest);

    const [attachments, setAttachments] = useState<AttachmentBasicDetail[]>([]);
    const [attachmentsTotalCount, setAttachmentsTotalCount] = useState(0);
    const [searchFlag, setSearchFlag] = useState(false);
    const [imageDetailView, setImageDetailView] = useState<ImageDetail>();
    const [isImageViewOpen, setIsImageViewOpen] = useState(false);
    const [isPDFViewOpen, setIsPDFViewOpen] = useState(false);
    const [pdfDetailView, setPDFDetailView] = useState<PDFDetail> ();

    const closeView = () => {
        setIsImageViewOpen(false);
        setIsPDFViewOpen(false);
    };

    const initialAttachmentListRequest = {
        pageIndex: 1,
        pageSize: 10,
        sortBy: "prjCode",
        sortDirection: SortDirection.DESC,
    };

    const AttachmentListGridColumns: GridColDef[]= [
        {
            field: "attachID", 
            headerName:"Id",
            width: 70,
        },
        {
            field:"prjCode", 
            headerName: "Project Code",
            width: 140,
        },
        {
            field:"stockCode", 
            headerName: "Stock Code",
            width: 170,
        },
        {
            field:"baseCode", 
            headerName: "Base Code",
            width: 150,
        },
        {
            field:"attachmentTypeDisplayText", 
            headerName:"Attachment Type",
            width: 200,
        },
        {
            field: "attachmentDate",
            headerName: "Attachment Date",
            width: 180,
            valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
        },
        {
            field: "fileName",
            headerName: "File Name",
            width: 200
        },
        {
            field: "level",
            headerName: "Level",
            width: 200
        },
        {
            field: "image",
            headerName: "Preview",
            renderCell: (params) => {
                return(
                    <div className="attachment-grid-image-cell">
                        {   //if the file type is image or not
                            GetFileType(params.row.fileName).isImage 
                            ?
                            <Box className = "image-thumbnail-container">
                                <CardMedia
                                    component = "img"
                                    src={params.value} 
                                    alt={`Attachment ${params.row.fileName}`} 
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imageNotAvailable;
                                    }}
                                />
                                <div style={{ position: 'absolute', display: 'flex', gap: '5px' }}>
                                    <IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handleAttachmentDownload(params.row.fileName)}
                                    >
                                        <DownloadIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handleImageSlider(params.row.fileName)}
                                    >
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            </Box> 
                            : 
                            //else display according icon
                            <Box className = "image-thumbnail-container"
                                    sx = {{
                                        backgroundColor: "rgba(0,0,0,.05)",
                                        height: "66px",
                                        width: "66px",
                                        textAlign: "center",
                                    }}
                            >
                                {   //getting the Icon 
                                    GetIconByFileType(params.row.fileName)}
                                <div style={{ position: 'absolute', display: 'flex', gap: '5px' }}>
                                    <IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handleAttachmentDownload(params.row.fileName)}
                                    >
                                        <DownloadIcon fontSize="small" />
                                    </IconButton>
                                    {GetFileType(params.row.fileName).isPdf  && (<IconButton
                                        className="attachment-hover-icon-button"
                                        size="small"
                                        onClick={() => handlePDFViewer(params.row.fileName)}
                                    >
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>)}
                                </div>
                            </Box> 
                        }
                    </div>
                )
            },
            width: 100,
            cellClassName: "sidebarImageListing",
            sortable:false
        },
    ];

    useEffect(() => {
        if(searchFlag){
            fetchAttachments();
        }
    },[attachmentListRequestState]);

    const fetchAttachments = async () => {
        try{
            setAttachments([]);
            setAttachmentsTotalCount(0);
            api 
                .post<ApiResponse<AttachmentListResponse>>("/Attachment/list-filter", attachmentListRequestState)
                .then(async (response) => {
                    if(response.isSuccess){
                        setAttachmentsTotalCount(response?.data?.totalCount);
                        const attachments: AttachmentBasicDetail[] = (response?.data?.attachments || []);
                        const mappedAttachments = attachments.map((attachment, index) => ({
                            ...attachment,
                            //setting the ID to index since there are no unique ID attachId && PrjCode may be duplicate
                            id: index,
                            image: attachment.fileName ? `${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(attachment.fileName)}` : imageNotAvailable
                        }));
                        setAttachments(mappedAttachments);
                    }
                })
                .catch((err: any) => {});
        }catch (error){
            console.error("Exception from attachment list", error);
        }
    };

    const handleApplyFilterClick = (filterValues: AttachmentSelectedFilters) => {
        setSearchFlag(true);
        if (filterValues.projectCode) {
            dispatch(updateAttachmentListRequest({
              ...attachmentListRequestState,
              projectCode: filterValues.projectCode,
              pageIndex: 1,
            }));
        } else if (Object.values(filterValues)?.length) {
            dispatch(updateAttachmentListRequest({
              ...attachmentListRequestState,
              ...filterValues,
              pageIndex: 1,
            }));
        } else {
            //reset the filters
            setSearchFlag(false);
            dispatch(updateAttachmentListRequest(initialAttachmentListRequest));
        }
    };

    const getSortFieldName = (gridFieldName: string): string => {
        return gridFieldName;
    };

    const onSortChange = (sorting: GridSortModel) => {
        dispatch(updateAttachmentListRequest({
          ...attachmentListRequestState,
          sortBy: getSortFieldName(sorting[0].field),
          sortDirection:
            sorting[0].sort === "asc" ? SortDirection.ASC : SortDirection.DESC,
        }));
    };

    const onPaginationChange = (pagination: GridPaginationModel) => {
        dispatch(updateAttachmentListRequest({
            ...attachmentListRequestState,
            pageIndex: pagination.page + 1,
            pageSize: pagination.pageSize,
        }));
    };

    const gridToolBar = () => (
        <GridToolbarContainer>
            <div style={{ flexGrow: 1, textAlign: "center", padding: 5,
                display: "flex", justifyContent: "flex-start"  }}>
                <GridToolbarColumnsButton />                  
                <GridToolbarQuickFilter sx ={{width: "20%", marginLeft: "1%"}}/>
            </div>
        </GridToolbarContainer>
    );

    const handleImageSlider = (fileName) => {
        setImageDetailView({
            fileName: fileName,
            imageUrl: `${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(fileName)}&height=700&width=700`
        });
        setIsImageViewOpen(true);
    };

    const handleAttachmentDownload = async (link: string) => {
        try{
            window.open(`${process.env.REACT_APP_API_URL}/Attachment/RetrieveFile?fileName=${encodeURIComponent(link)}`, '_blank');
        } catch (error: any) {
            console.error("Exception from RetrieveFile API", error);
        }
    };


    
    const handlePDFViewer = (fileName) => {
        setPDFDetailView({
            fileName: fileName,
        });
        setIsPDFViewOpen(true);
    }

    return (
        <>
            <AttachmentFilter 
                onApplyFilter = {handleApplyFilterClick}
            />
            <ImageViewModal
                images = {imageDetailView}
                isOpen = {isImageViewOpen}
                onClose = {closeView}
            />
            { 
            isPDFViewOpen && <PDFViewModal
                pdf={pdfDetailView}
                isOpen = {isPDFViewOpen}
                onClose = {closeView}
            />
            }

            {searchFlag && <DataGrid 
                rows={attachments}
                sortingOrder={['desc', 'asc']}
                columns={AttachmentListGridColumns} 
                initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10
                      },
                    },
                }}
                disableColumnMenu={true}
                sortModel={[
                    {
                      field: attachmentListRequestState.sortBy,
                      sort:
                      attachmentListRequestState.sortDirection == SortDirection.ASC
                          ? "asc"
                          : "desc",
                    },
                  ]}
                rowCount={attachmentsTotalCount}
                pageSizeOptions={[10, 25, 50, 100]}
                sortingMode="server"
                paginationMode="server"
                onSortModelChange={onSortChange}
                onPaginationModelChange={onPaginationChange}
                slots={{
                    toolbar: gridToolBar,
                }}
                paginationModel={{
                    page: attachmentListRequestState.pageIndex - 1, 
                    pageSize: attachmentListRequestState.pageSize,
                }}
                getRowHeight={() => 'auto'}
                rowSelection={false}
            />}
        </>
    )

};

export default AttachmentList;