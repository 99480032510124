import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Checkbox,
  FormGroup,
  Typography,
} from "@mui/material";
import { FormModalDialogProps } from "../../../models/ModalDialogProps";
import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { SecurityGroup } from "../../../models/SecurityGroup";

const AssignmentFormModal = (props: FormModalDialogProps & { isButtonDisabled: boolean }) => {
  const { isOpen, onClose, formik, isButtonDisabled } = props;
  const [securityGroups, setSecurityGroups] = useState<SecurityGroup[]>([]);

  useEffect(() => {
    fetchSecurityGroups();
  }, [])

  const fetchSecurityGroups = async () => {
    try {
      const resp = await api.get<ApiResponse<SecurityGroup[]>>('/Assignment/security-group');
      if (resp?.isSuccess) {
        setSecurityGroups(resp.data || []);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogActions>
        <Button onClick={onClose}>x</Button>
      </DialogActions>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label={
                  (formik.touched.name && formik.errors.name) ||
                  "Enter Description"
                }
                type="text"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                  !!(formik.touched.name && formik.errors.name)
                }
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                id="hierarchy"
                name="hierarchy"
                label={
                  (formik.touched.hierarchy && formik.errors.hierarchy) || "Enter Hierarchy"
                }
                type="number"
                error={formik.touched.hierarchy && formik.errors.hierarchy}
                onBlur={formik.handleBlur}
                value={formik.values.hierarchy}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          {(formik.touched.assignmentGroups && formik.errors.assignmentGroups) ?
            <Typography component="h4" color="error">Select at least one Security Group for this Assignment</Typography> :
            <h4>Assign Security Groups to Assignment</h4>}
          <FormGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                maxHeight: "400px",
                overflowX: "auto",
              }}
            >
              {securityGroups.filter(sg => sg.isActive !== false)
                .map((sg, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.assignmentGroups?.some(st => st.securityGroupId == sg.id && sg.isActive !== false)}
                        onChange={() => {
                          const assignmentGroups = formik.values.assignmentGroups;
                          if (assignmentGroups?.some(st => st.securityGroupId == sg.id)) {
                            // If security group is already selected, remove it
                            const updatedAssignment = assignmentGroups?.filter(
                              (st) => st.securityGroupId !== sg.id
                            );
                            formik.setFieldValue("assignmentGroups", updatedAssignment);
                          } else {
                            // Transform the security group object to match the desired structure
                            const updatedSecurityGroup = {
                              id: 0,
                              securityGroupId: sg.id,
                              securityGroupDisplayText: sg.groupName,
                            };

                            // Map over the assignment groups to update their structure
                            const addNewGroup = assignmentGroups.map(sg =>
                              sg.id !== 0
                                ? {
                                  id: 0,
                                  securityGroupId: sg.securityGroupId,
                                  securityGroupDisplayText: sg.groupName,
                                }
                                : sg // Return the original security group if id is 0
                            );

                            // Add the updated security group to the array
                            addNewGroup.push(updatedSecurityGroup);
                            // If security group is not selected, add it
                            formik.setFieldValue("assignmentGroups", addNewGroup);
                          }
                        }}
                        name="assignmentGroups"
                      />
                    }
                    key={`assignmentGroups.${sg.id}`}
                    label={sg.groupName}
                  />
                ))}
            </div>
          </FormGroup>
          <h4>Set Active</h4>
          <RadioGroup
            row
            name="isActive"
            value={formik.values.isActive}
            onChange={(event) => formik.handleChange(event)}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" disabled={isButtonDisabled}>
            {formik.values.id > 0
              ? "Update Assignment"
              : "Add Assignment"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssignmentFormModal;
