import { GridColDef } from "@mui/x-data-grid";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { Tag } from "../../../models/Tag";
import { useEffect, useState } from "react";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import TagModal from "./TagModal";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SessionDetails } from "../../../models/SessionDetails";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function TagSetting() {
    const [tagList, setTagList] = useState<Tag[]>([]);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disabledSubmit, setDisableSubmit] = useState(false);
    
    const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

    const TagSupplierGridCollumns: GridColDef[] = [
        { field: "id", headerName: "Id", width: 100 },
        { field: "name", headerName: "Tag Name", width: 400 },
        {
            field: "isActive",
            headerName: "Is Active",
            width: 200,
            sortable: false,
            renderCell: (params: any) => (
                <>
                    <Switch
                        checked={params.value}
                        onChange={async (e) => {
                        await updateTagActive(params.row, e.target.checked);
                        }}
                        color={"primary"}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled = {!manageAppSetting}
                    />
                </>
            )
        },
        {
            field: "updatedDate",
            headerName: "Updated Date",
            width: 200,
            valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            getActions: (params: any) => [
              GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
              GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
            ],
          },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const getTagResponse = await api.get<ApiResponse<Tag[]>>(
            "/Picklist/tags"
          );
          setTagList(getTagResponse?.data || []);
    };

    const handleEditClickOpen = (gridParams: any) => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
        formik.setValues({...gridParams.row});
    };

    const handleDeleteModalOpen = (gridParams: any) => {
        setDeleteModalOpen(true);
        formik.setValues({...gridParams.row});
    };

    const handleAddClickOpen = () => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
    };

    const handleCloseModal = () => {
        setShowAddUpdateModal(false);
        setDeleteModalOpen(false);
        formik.resetForm();
    };
    
    const updateTagActive = (row: Tag, isActive: boolean) => {
        row.isActive = isActive;
        try {
          api
            .put<ApiResponse<number>>("/Picklist/tag", row)
            .then(async (response: any) => {
              if (response.isSuccess) {
                toast.success(response.message);
                await fetchData();
              } else {
                throw new Error(response.message);
              }
            })
            .catch(() => {});
        } catch (err: any) {
          toast.error(err.message);
        }
    };

    const deleteSubmit = () => {
        try{
            api
                .delete<ApiResponse<SessionDetails>>(`/Picklist/tag/${formik.values.id}`)
                .then(async (response: any) => {
                    if(response.isSuccess) {
                        setDeleteModalOpen(false);
                        formik.resetForm();
                        toast.success(response.message);
                        await fetchData();
                    }else {
                        throw new Error(response.message);
                    }
                })
                .catch(()=>{});
        }catch (error: any){
            throw new Error(error.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            id: 0, 
            name: "",
            isActive: true,
        },
        validationSchema: Yup.object({
            name: Yup.string()
              .trim()
              .min(1, "Tag Name must be at least 1 characters")
              .max(40, "Tag Name must be at most 40 characters")
              .required("Tag Name is required")
              .test(
                "check-name-duplicate",
                "Tag Name already exists",
                (value) => {
                  return !tagList.some(
                    (item) =>
                      item.name.trim().toLowerCase() === value.trim().toLowerCase() &&
                      item.id !== formik.values.id
                  );
                }
              ),
          }),
        onSubmit: async (values) => {
            setDisableSubmit(true);
            if(values.id>0) {
                api
                    .put<ApiResponse<number>>("/Picklist/tag", {
                        id: values.id,
                        name: values.name.trim(),
                        isActive:JSON.parse(`${values.isActive}`)
                    })
                    .then(async(response: any) => {
                        if(response.isSuccess){
                            setShowAddUpdateModal(false);
                            formik.resetForm();
                            toast.success(response.message);
                            await fetchData();
                        } else {
                            throw new Error(response.message);
                        }
                    })
                    .catch(()=>{});
            } else {
                api
                    .post<ApiResponse<number>>("/Picklist/tag", {
                        name: values.name.trim(),
                        isActive:JSON.parse(`${values.isActive}`)
                    })
                    .then(async(response: any) => {
                        if(response.isSuccess){
                            setShowAddUpdateModal(false);
                            formik.resetForm();
                            toast.success(response.message);
                            await fetchData();
                        } else {
                            throw new Error(response.message);
                        }
                    })
                    .catch(()=>{});
            }
        }
    });

    return (
        <>
            <div>
                {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
                    Add Tag
                </Button>}
                <TagModal 
                    isOpen={showAddUpdateModal} 
                    onClose={handleCloseModal} 
                    formik={formik}
                    isButtonDisabled={disabledSubmit}
                />
                <DeleteValidationModal 
                    addModalOpen={deleteModalOpen} 
                    handleCloseModal={handleCloseModal} 
                    deleteSubmit={deleteSubmit}
                    fieldIdentifier={"Tag Name"}
                />
            </div>
            <SettingGrid 
                data={tagList} 
                column={TagSupplierGridCollumns}
                sortByField="id"
                sortByOrder="desc"
                columnVisibility={{
                    actions: manageAppSetting,
                  }}          
            />
        </>
    );
};

