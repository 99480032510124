import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import {
  GRID_ACTION_DELETE_ICON,
  GRID_ACTION_EDIT_ICON,
} from "../../../components/GridUtilityComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { PrjStage, PrjStageWithStatus } from "../../../models/PrjStage";
import PrjStageFormModal from "./ProjectStageModal";
import GridCellChipsContent from "../../../components/GridCellChipsContent";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function () {
  const [rows, setRows] = useState<PrjStageWithStatus[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const formDefaultValue = {
    id: 0,
    description: "",
    family: "",
    statuses: [],
    tags: [],
    isActive: true,
  };

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const PrjStageGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 80 },
    { field: "description", headerName: "Description", width: 150 },
    {
      field: "statuses",
      headerName: "Statuses",
      type: "Array",
      width: 480,
      sortable: false,
      renderCell: (params: any) => {
        if (!params.value) {
          return params.value;
        } else {
          return (
            <GridCellChipsContent cellData={params.row.statuses} keyField="id" displayTextFiled="description" />
          );              
        }
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      type: "Array",
      width: 480,
      sortable: false,
      renderCell: (params: any) => {
        if (!params.value) {
          return params.value;
        } else {
          return (
            <GridCellChipsContent cellData={params.row.tags} keyField="id" displayTextFiled="name" />
          );              
        }
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable: false,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={(e) => {
              params.api.setEditCellValue(
                { id: params.id, field: params.field },
                e.target.checked
              );
              updatePrjStageActive(params.row, e.target.checked);
            }}
            color="primary"
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 100,
      valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClick),
        GRID_ACTION_DELETE_ICON(params, handleDeleteClick),
      ],
    },
  ];

  const handleEditClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteClick = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const resp = await api.get<ApiResponse<PrjStageWithStatus[]>>(
      "/Picklist/stages"
    );

    const sortedList = resp?.data?.sort((rec1, rec2) => rec2.id - rec1.id);
    setRows(sortedList || []);
  };

  const updatePrjStageActive = (
    attachmentType: PrjStage,
    active: boolean
  ) => {
    const data = {
      id: attachmentType.id,
      isActive: active,
    };

    api
      .put<ApiResponse<boolean>>("/Picklist/stage-activation", data)
      .then(async (response: any) => {
        if (response.isSuccess) {
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {});
  };

  const formik = useFormik({
    initialValues: formDefaultValue,
    validateOnChange: false,
    validationSchema: Yup.object({
      description: Yup.string()
        .trim()
        .min(1, "Description must be at least 1 characters")
        .max(100, "Description must be at most 40 characters")
        .required("Description is Required")
        .test(
          "check-rank-duplicate",
          "Stage with this name already exists",
          (value) => {
            return !rows.some(
              (item) =>
                item.description.trim().toLowerCase() === value.trim().toLowerCase() &&
                item.id !== formik.values.id
            );
          }
        ),
        family: Yup.string()
          .trim()
          .min(1, "Family must be at least 1 characters")
          .max(100, "Family must be at most 40 characters"),
        statuses: Yup.array().min(1, "Please select at least 1 status for this stage"),
        tags: Yup.array().min(1, "Please select at least 1 tags for this stage")
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/stage", {
            id: values.id,
            description: values.description.trim(),
            statuses: values.statuses?.map(st => st.id),
            isActive: JSON.parse(`${values.isActive}`), 
            tags: values.tags?.map(t => t.id),   
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/stage", {
            id: values.id,
            description: values.description.trim(),
            statuses: values.statuses?.map(st => st.id),
            isActive: JSON.parse(`${values.isActive}`),
            tags: values.tags?.map(t => t.id),   
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  const deletePrjStage = () => {
    api
      .delete<ApiResponse<number>>(`/Picklist/stage/${formik.values.id}`)
      .then(async (response: any) => {
        formik.resetForm();
        if (response.isSuccess) {
          setDeleteModalOpen(false);
          toast.success(response.message);
          await fetchData();
        } else {
          throw new Error(response.message);
        }
      })
      .catch(() => {
        formik.resetForm();
      });
  };

  return (
    <>
      {showAddUpdateModal && <PrjStageFormModal
        isOpen={showAddUpdateModal}
        onClose={handleCloseModal}
        formik={formik}
        isButtonDisabled={disableSubmit}
      />}

      <DeleteValidationModal
        addModalOpen={deleteModalOpen}
        handleCloseModal={handleCloseModal}
        deleteSubmit={deletePrjStage}
        fieldIdentifier={"Project Stage Description"}
      />
      {manageAppSetting && <Button variant="contained" onClick={handleAddClick}>
        Add Stage
      </Button>}

      <SettingGrid
        data={rows}
        column={PrjStageGridCollumns}
        sortByField="id"
        sortByOrder={"desc"}
        rowHeightGetter={() => 'auto'}
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}

