import { Chip, Stack } from "@mui/material";

type GridCellChipsContentProps = {
    keyField: string,
    displayTextFiled: string,
    cellData: any[],
}

export default function GridCellChipsContent(props: GridCellChipsContentProps) {
    return (
        <div style={{width: "100%", padding: 10}}>
          {props.cellData.map((item: any) => (                
            <Stack direction="row" key={item[props.keyField]} spacing={1} 
              component="span" 
              sx={{ margin:"3px", display:"inline-block" }}>
                <Chip label={ item[props.displayTextFiled]} component="span" />
            </Stack>
          ))}
        </div>
      ); 
}