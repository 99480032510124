import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { FormModalDialogProps } from "../../../models/ModalDialogProps";

const FactoryStatusFormModal = (props: FormModalDialogProps & { isButtonDisabled: boolean }) => {
  const { isOpen, onClose, formik, isButtonDisabled } = props;

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogActions>
          <Button onClick={onClose}>x</Button>
        </DialogActions>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="description"
                  name="description"
                  label={
                    (formik.touched.description && formik.errors.description) ||
                    "Enter Status Name"
                  }
                  type="text"
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.description && formik.errors.description)
                  }
                  onBlur={formik.handleBlur}
                />
            <h4>Set Active</h4>
            <RadioGroup
              row
              name="isActive"
              value={formik.values.isActive}
              onChange={(event) => formik.handleChange(event)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" disabled={isButtonDisabled}>
              {formik.values.id > 0
                ? "Update Factory Status"
                : "Add Factory Status"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default FactoryStatusFormModal;
