import { 
    Button,
    Dialog, 
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
 } from "@mui/material";

interface SupplierModalProps {
    isOpen: boolean; 
    onClose: VoidFunction;
    formik: any;
};

const SuplierModal = (props: SupplierModalProps & { isButtonDisabled: boolean }) => {
    const {isOpen, onClose, formik, isButtonDisabled} = props; 

    return (
        <>
            <Dialog open = {isOpen} onClose = {onClose} fullWidth>
                <DialogActions>
                    <Button onClick = {onClose}>x</Button>
                </DialogActions>
                <form onSubmit = {formik.handleSubmit}>
                    <DialogContent>
                    <Grid container spacing={2}>   
                        <Grid item xs={8}>
                            <TextField
                                autoFocus
                                margin = "dense"
                                id = "name"
                                name = "name"
                                label = {
                                    formik.errors.name && formik.touched.name ?
                                    formik.errors.name : 
                                    "Enter Supplier Name"
                                }
                                type = "text"
                                fullWidth
                                value = {formik.values.name}
                                onChange = {formik.handleChange}
                                error = {formik.touched.name && formik.errors.name}
                                onBlur = {formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                margin = "dense"
                                id = "sapCode"
                                name = "sapCode"
                                label = {
                                    formik.errors.sapCode && formik.touched.sapCode ?
                                    formik.errors.sapCode : 
                                    "Enter SAP Code"
                                }
                                type = "text"
                                // helperText="SAP Code Must be a Number or 'N/A'"
                                fullWidth
                                // value = {formik.values.sapCode.toUpperCase()}
                                value = {formik.values.sapCode}
                                onChange = {formik.handleChange}
                                error = {formik.touched.sapCode && formik.errors.sapCode}
                                onBlur = {formik.handleBlur}
                            />
                            </Grid>
                        </Grid>
                        <h4>Set Active</h4>
                        <RadioGroup
                            row
                            name = "isActive"
                            value = {formik.values.isActive}
                            onChange = {(event) => formik.handleChange(event)}
                        >
                            <FormControlLabel value = {true} control = {<Radio/>} label = "Active"/>
                            <FormControlLabel value = {false} control = {<Radio/>} label = "Inactive"/>
                        </RadioGroup>
                        <DialogActions>
                            <Button variant="contained" type="submit" disabled={isButtonDisabled}>
                                {formik.values.id > 0? "Update Supplier Name" : "Add Supplier Name"}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </>
    )
};

export default SuplierModal;