import { Box } from "@mui/material";
import { DataGrid, GridSortModel} from "@mui/x-data-grid";
import { useState } from "react";
import SessionService from "../../services/SessionService";
import { Permissions } from "../../models/Enum";
import CustomToolbar from "../../components/CustomToolBar";
const UserListing = ({ userListData, usersGridschema }) => {
  const sessionService = SessionService.getInstance();
  const manageUserPermission = sessionService.hasPermission(Permissions.UpdateUser);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        sx={{ flexGrow: 1 }}
        rows={userListData}
        columns={usersGridschema}
        slots={{ toolbar: CustomToolbar }}
        // slotProps={{
        //   toolbar: {
        //     showQuickFilter: true,
        //   },
        // }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(sort) => setSortModel(sort)}
        disableRowSelectionOnClick={true}
        getRowHeight={() => 'auto'}
        columnVisibilityModel={{
          action: manageUserPermission,
        }}
      />
    </Box>
  );
};
export default UserListing;
