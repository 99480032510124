export const allCurrency = ["USD", "INR", "Pound"];

export const randomColors = [
  "#dad21f",
  "#9a3ef6",
  "#d890f9",
  "#6cd4bd",
  "#b6ba6c",
  "#a48348",
  "#7b9fb8",
];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const USER_ROLES = {
  ADMIN:"Admin",
  USER:"User"
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY LT';

export const SignalREvents = {
  ReceivedMessages : {
    itemCreated: "itemCreated",
    itemFieldUpdated: "itemFieldUpdated",
    userOpendItemInEditMode: "userOpendItemInEditMode",
    itemModified: "itemModified",
    processExecutiveReview: "processExecutiveReview",
    productCommentUpdated: "productCommentUpdated"
  },
  SendMessages: {
    sendItemCreated: "onItemCreated"
  }
}

export const NOTIFICATION_TYPE_FRIENDLY_NAME = {
  NewProduct: "New Product",
  StageStatusTagUpdate:"Stage/Status/Tag Update",
  AttachmentUpdate: "Attachment Update", 
  AssignmentUpdate: "Assignment Update",
  ProcessExecutiveReview: "Process Executive Review",
  ProductCommentUpdate: "Product Comment Update",
  ProductDelete: "Product Delete",
  AttachmentAdd: "Attachment Add",
  AttachmentRemove: "Attachment Remove",
  NewProductComment: "New Product Comment"
};
