import { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { isTokenExpired } from './Access-Validate'
import SessionService from '../services/SessionService';

const ProtectedRoutes = () => {
    const tokenExpired = isTokenExpired();
    if(tokenExpired) {
        SessionService.getInstance().clearSession();
        SessionService.getInstance().clearReduxState();
    }

    return(
        !tokenExpired ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default ProtectedRoutes