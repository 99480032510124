import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { FormModalDialogProps } from "../../../models/ModalDialogProps";

const AttachmentTypeModal = (props: FormModalDialogProps & { isButtonDisabled: boolean }) => {
  const { isOpen, onClose, formik, isButtonDisabled } = props;

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogActions>
          <Button onClick={onClose}>x</Button>
        </DialogActions>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="attachType"
                  name="attachType"
                  label={
                    (formik.touched.attachType && formik.errors.attachType) ||
                    "Enter Attachment Type"
                  }
                  type="text"
                  fullWidth
                  value={formik.values.attachType}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.attachType && formik.errors.attachType)
                  }
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="prefix"
                  name="prefix"
                  label={
                    (formik.touched.prefix && formik.errors.prefix) ||
                    "Enter Prefix"
                  }
                  type="text"
                  fullWidth
                  value={formik.values.prefix.toUpperCase()}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.prefix && formik.errors.prefix)}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <h4>Set Active</h4>
                <RadioGroup
                  row
                  name="isActive"
                  value={formik.values.isActive}
                  onChange={(event) => formik.handleChange(event)}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <h4>Set Attachment Level</h4>
                <RadioGroup
                  row
                  name="lvl"
                  value={formik.values.lvl}
                  onChange={(event) => formik.handleChange(event)}
                >
                  <FormControlLabel
                    value={"SC"}
                    control={<Radio />}
                    label="StockCode"
                  />
                  <FormControlLabel
                    value={"BC"}
                    control={<Radio />}
                    label="BaseCode"
                  />
                </RadioGroup>
              </Grid>
            </Grid>            
          </DialogContent>
          <DialogActions>
            <Button variant="contained"
             type="submit"
             disabled={isButtonDisabled}
            >
              {formik.values.id > 0
                ? "Update Attachment Type"
                : "Add Attachment Type"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AttachmentTypeModal;
