import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  message,
  titleForConfirmYesButton = "Yes",
  titleForConfirmNoButton = "No",
  handleConfirmYes,
  handleConfirmNo
}) => {

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} >
        <DialogTitle>{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography id="modal-modal-description">
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleConfirmYes}>{titleForConfirmYesButton}</Button>
          <Button variant="outlined" onClick={handleConfirmNo}>{titleForConfirmNoButton}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;