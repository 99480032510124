import { useEffect, useState } from "react"
import { Style } from "../../../models/Style"
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { GridColDef } from "@mui/x-data-grid";
import { Button, Switch } from "@mui/material";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { toast } from "react-toastify";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { SessionDetails } from "../../../models/SessionDetails";
import { useFormik } from "formik";
import StyleSettingModal from "./StyleSettingModal";
import * as Yup from "yup";

export default function StyleSetting() {
    const [styleList, setStyleList] = useState<Style[]>([]);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
  

    const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

    const StyleGridCollumns: GridColDef[] = [
        { field: "id", headerName: "Id", width: 100 },
        { field: "description", headerName: "Style Description", width: 300 },
        {
          field: "isActive",
          headerName: "Is Active",
          sortable: false,
          width: 200,
          renderCell: (params: any) => (
            <>
              <Switch
                checked={params.value}
                onChange={async (e) => {
                  await updateStyleActive(params.row, e.target.checked);
                }}
                color={"primary"}
                inputProps={{ "aria-label": "controlled" }}
                disabled = {!manageAppSetting}
              />
            </>
          ),
        },
        {
          field: "updatedDate",
          headerName: "Updated Date",
          width: 200,
          valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
        },
        {
          field: "actions",
          type: "actions",
          headerName: "Actions",
          width: 200,
          getActions: (params: any) => [
            GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
            GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
          ],
        },
      ];
    
    useEffect(()=>{
        fetchData();
    }, []); 

    const fetchData = async () => {
        const getStyleResponse = await api.get<ApiResponse<Style[]>> ("/PickList/styles")
        setStyleList(getStyleResponse?.data || []);
    };

    const updateStyleActive = async (row: Style, isActive: boolean) => {
        row.isActive = isActive;
        try{
            api
            .put<ApiResponse<Style>>("/Picklist/style", row)
            .then(async (response: any) => {
              if (response.isSuccess) {
                toast.success(response.message);
                await fetchData();
              } else {
                throw new Error(response.message);
              }
            })
            .catch(() => {});
        } catch( error: any ){
            toast.error(error.message);
        }
    };

    const handleCloseModal = () => {
        setDeleteModalOpen(false);
        setShowAddUpdateModal(false);
        formik.resetForm();
    };

    const handleEditClickOpen = (gridParams: any) => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
        formik.setValues({ ...gridParams.row });
    };

    const handleAddClickOpen = () => {
        formik.resetForm();
        setShowAddUpdateModal(true);
        setDisableSubmit(false);
    };
    
    const handleDeleteModalOpen = (gridParams: any) => {
        setDeleteModalOpen(true);
        formik.setValues({ ...gridParams.row });
    };

    const deleteSubmit  = async () => {
        try {
            api
              .delete<ApiResponse<SessionDetails>>(
                `/Picklist/style/${formik.values.id}`
              )
              .then(async (response: any) => {
                if (response.isSuccess) {
                  setDeleteModalOpen(false);
                  formik.resetForm();
                  toast.success(response.message);
                  await fetchData();
                } else {
                  throw new Error(response.message);
                }
              })
              .catch(() => {});
          } catch (err: any) {
            console.error("Exception from style setting", err);
          }
    };

    const formik = useFormik({
        initialValues: {
            id: 0,
            description: "",
            isActive: true
        },
        validationSchema: Yup.object({
            description: Yup.string()
              .trim()
              .required("Style Description is required")
              .min(1, "Style Description must be at least 1 characters")
              .max(40, "Style Description must be at most 40 characters")
              .test(
                "check-description-duplicate",
                "Style Description already exists",
                (value) => {
                  return !styleList.some(
                    (item) =>
                      item.description.trim().toLowerCase() ===
                        value.trim().toLowerCase() && item.id !== formik.values.id
                  );
                }
              ),
          }),
        onSubmit: async (values) => {
            setDisableSubmit(true);
            if (values.id > 0) {
                api
                  .put<ApiResponse<number>>("/Picklist/style", {
                    id: values.id,
                    description: values.description.trim(),
                    isActive: JSON.parse(`${values.isActive}`),
                  })
                  .then(async (response: any) => {
                    if (response.isSuccess) {
                      setShowAddUpdateModal(false);
                      formik.resetForm();
                      toast.success(response.message);
                      await fetchData();
                    } else {
                      throw new Error(response.message);
                    }
                  })
                  .catch(() => {});
              } else {
                api
                  .post<ApiResponse<number>>("/Picklist/style", {
                    description: values.description,
                    isActive: JSON.parse(`${values.isActive}`),
                  })
                  .then(async (response: any) => {
                    if (response.isSuccess) {
                      setShowAddUpdateModal(false);
                      formik.resetForm();
                      toast.success(response.message);
                      await fetchData();
                    } else {
                      throw new Error(response.message);
                    }
                  })
                  .catch(() => {});
              }
        }
    })
        
    return (
        <>
            <div>
                {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
                    Add Style
                </Button>}
                <StyleSettingModal 
                    isOpen={showAddUpdateModal} 
                    onClose={handleCloseModal} 
                    formik={formik} 
                    isButtonDisabled= {disableSubmit}               
                />
                <DeleteValidationModal
                    addModalOpen={deleteModalOpen}
                    handleCloseModal={handleCloseModal}
                    deleteSubmit={deleteSubmit}
                    fieldIdentifier={"Style"}
                />
            </div>
            <SettingGrid
                data={styleList}
                column={StyleGridCollumns}
                sortByField="id"
                sortByOrder="desc"
                columnVisibility={{
                actions: manageAppSetting,
                }}
            />
        </>
    );
}