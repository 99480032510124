import React from 'react';
import { Card, CardContent, Typography, TextField, Button, FormControl } from '@mui/material';
import imageNotAvailable from "../no-image.png";
import { THUMBNAIL_IMAGE_API_URL } from "../pages/Products/EditItem/Constants";

const ProductCard = ({ product, onAddNoteChange, onSubmit }) => {

  const renderProductDetailContent = (field, productId) => (
    <span
      className="product-detail-content"
      onClick={() => window.open(`${window.location.origin}/edit-product/${productId}`, '_blank')}>
      {field || "-"}
    </span>
  );

  return (
    <Card className="product-card">
      <div className="product-image-container">
      <img
        src={`${THUMBNAIL_IMAGE_API_URL}?fileName=${encodeURIComponent(product.imageFileName)}&height=400&width=400`}
        alt={product.shortDesc}
        className="product-thumbnail"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = imageNotAvailable;
        }}
        onClick={() => window.open(`${window.location.origin}/edit-product/${product.id}`, '_blank')}
      />
      </div>
      <CardContent className="product-details">
        <Typography variant="h6" className="product-name bold-text">
          {renderProductDetailContent(product.shortDesc, product.id)}
        </Typography>
        <Typography variant="body2"><span className="bold-text">Designer:&nbsp;</span>
          {renderProductDetailContent(product.marketingDesignerDisplayText, product.id)}
        </Typography>
        <Typography variant="body2"><span className="bold-text">Category:&nbsp;</span>
          {renderProductDetailContent(product.categoryDisplayText, product.id)}
        </Typography>
        <Typography variant="body2"><span className="bold-text">Base Code:&nbsp;</span>
          {renderProductDetailContent(product.baseCode, product.id)}
        </Typography>
        <Typography variant="body2"><span className="bold-text">Function:&nbsp;</span>
          {renderProductDetailContent(product.functionDisplayText, product.id)}
        </Typography>
        <div className="product-detail-row">
          <Typography style={{ marginRight: "5px" }} variant="body2"><span className="bold-text">Height:&nbsp;</span>
            {renderProductDetailContent(product.height, product.id)}
          </Typography>
          <span style={{ marginLeft: "2px", marginRight: "2px" }}>|&nbsp;</span>
          <Typography variant="body2"><span className="bold-text">Width:&nbsp;</span>
            {renderProductDetailContent(product.width, product.id)}
          </Typography>
        </div>
        <FormControl style={{ margin: "5px 5px", width: "100%" }}>
          <TextField
            variant="outlined"
            placeholder="Add Notes Here"
            className="note-input"
            margin="dense"
            onChange={(e) => onAddNoteChange(e.target.value)}
            value={product.notes}
          />
        </FormControl>
        <div className="button-container">
          <Button style={{ marginRight: "10px", width: "120px" }} onClick={() => onSubmit("Approve")} variant="contained" color="primary">Approve</Button>
          <Button style={{ width: "120px" }} variant="outlined" onClick={() => onSubmit("Reject")} color="secondary">Reject</Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
