import { Button, Menu, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Tag } from "../models/Tag";
import { useState } from "react";
import SessionService from "../services/SessionService";
import { Permissions } from "../models/Enum";
import StarRoundedIcon from '@mui/icons-material/StarRounded';

type TagsProps = {
  selectedTagOptions: Tag[];
  tagDropDownOptions: Tag[];
  uniqueTagsIdList: number[];
  handleRemoveTag: (tagId: number) => void;
  handleTagOptionSelect: (selectedOption: Tag) => void;
};

const Tags = (props: TagsProps) => {

  const {
    selectedTagOptions,
    tagDropDownOptions,
    handleRemoveTag,
    handleTagOptionSelect,
    uniqueTagsIdList,
  } = props;

  const [tagsMenuVisible, setTagsMenuVisible] = useState<null | HTMLElement>(null);
  const open = Boolean(tagsMenuVisible);

  const handleAddTag = (event: React.MouseEvent<HTMLElement>) => {
    setTagsMenuVisible(event.currentTarget);
  };

  const handleMenuClose = () => {
    setTagsMenuVisible(null);
  };

  const sessionService = SessionService.getInstance();

  //Seperate 2 arrays for special tag alway be at the bottom
  const itemsWithIcons = tagDropDownOptions.filter(item => !uniqueTagsIdList.includes(item.id));
  const itemsWithoutIcons = tagDropDownOptions.filter(item => uniqueTagsIdList.includes(item.id));
  //sorted array so tag for stage alway top and special tag alway bottom
  const sortedTagDropDownOptions = [...itemsWithoutIcons, ...itemsWithIcons];
  
  return (
    <>
      <div style={{ margin: "5px 0" }}>
        {selectedTagOptions.length > 0 &&
          selectedTagOptions.map((tagItem) => (
            <Button
              key={tagItem.id}
              variant="outlined"
              className="tag-selected-button"
              endIcon={
                sessionService.hasPermission(Permissions.ManageTags) &&
                <CloseIcon sx={{ cursor: "pointer" }} onClick={() => handleRemoveTag(tagItem.id)} />
              }
              sx={{ borderRadius: 28, textTransform: "none", }}
            >
                {
                  uniqueTagsIdList.includes(tagItem.id) ? (
                    tagItem.name
                  ) : (
                    <>
                      <StarRoundedIcon fontSize = "small"/>
                      {tagItem.name}
                    </>
                  )
                }
            </Button>
          ))}
        {sessionService.hasPermission(Permissions.ManageTags) && 
          tagDropDownOptions.length > 0 &&
          <Button
          id="basic-button"
          variant="contained"
          aria-haspopup="true"
          style={{ margin: "8px 5px" }}
          sx={{ borderRadius: 5, textTransform: "none" }}
          onClick={handleAddTag}
        >
          Add Tag
        </Button>}
        <Menu
          id="basic-menu"
          anchorEl={tagsMenuVisible}
          open={open}
          onClose={handleMenuClose}
          MenuListProps={{'aria-labelledby' : 'basic-button'}}
          sx={{ marginTop: "2px"}}
        >
          {sortedTagDropDownOptions && sortedTagDropDownOptions?.length > 0 &&
            sortedTagDropDownOptions.map((item) => (
              <MenuItem key={item.id} value={item.id} onClick={() => {
                handleTagOptionSelect(item);
                handleMenuClose();
              }}>
                {
                  uniqueTagsIdList.includes(item.id) ? (
                    item.name
                  ) : (
                    <>
                      <StarRoundedIcon fontSize = "small"/>
                      {item.name}
                    </>
                  )
                }
              </MenuItem>
            ))}
        </Menu>
      </div>
    </>
  )
}

export default Tags;