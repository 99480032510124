import { 
    Button,
    Dialog, 
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
 } from "@mui/material";

interface ProductFinishModalProps {
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any; 
};

const ProductFinishModal = (props: ProductFinishModalProps & { isButtonDisabled: boolean }) => {
    const {isOpen, onClose, formik, isButtonDisabled} = props;

    return(
        <>
            <Dialog open = {isOpen} onClose={onClose} fullWidth>
                <DialogActions>
                    <Button onClick={onClose}>x</Button>
                </DialogActions>
                <form onSubmit = {formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing= {2}>
                        <Grid item xs = {8}>
                            <TextField
                                autoFocus
                                margin = "dense"
                                id = "finishName"
                                name = "finishName"
                                label  = {
                                    formik.touched.finishName && formik.errors.finishName ? 
                                    formik.errors.finishName : 
                                    "Enter Finish Name"                                    
                                }
                                type = "text"
                                fullWidth
                                value = {formik.values.finishName}
                                onChange = {formik.handleChange}
                                error = {formik.touched.finishName && formik.errors.finishName}
                                onBlur = {formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs = {4}>
                            <TextField
                                margin ="dense"
                                id = "finishCode"
                                name = "finishCode"
                                label  = {
                                    formik.touched.finishCode && formik.errors.finishCode ? 
                                    formik.errors.finishCode : 
                                    "Enter Finish Code"                                    
                                }
                                type = "text" 
                                value = {formik.values.finishCode.toUpperCase()}
                                onChange={formik.handleChange}
                                error = {formik.touched.finishCode && formik.errors.finishCode}
                                onBlur = {formik.handleBlur}
                            />
                        </Grid>
                    </Grid>
                    <h4>Set Active</h4>
                    <RadioGroup
                        row
                        name = "isActive"
                        value = {formik.values.isActive}
                        onChange={(event) => formik.handleChange(event)}
                    >
                        <FormControlLabel value = {true} control = {<Radio/>} label = "Active"/>
                        <FormControlLabel value = {false} control = {<Radio/>} label = "Inactive" />
                    </RadioGroup>
                    <DialogActions>
                        <Button variant = "contained" type = "submit" disabled={isButtonDisabled}>
                            {formik.values.id>0 ? "Update Finish" : "Add Finish"}
                        </Button>
                    </DialogActions>
                </DialogContent>
                </form>
            </Dialog>
        </>
    )
};

export default ProductFinishModal;