import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import { DesignerRyt } from "../../../models/DesignerRyt";
import api from "../../../services/ApiService";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import RoyaltyDesignerModal from "./RoyaltyDesignerModal";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { SessionDetails } from "../../../models/SessionDetails";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function RoyaltyDesignerSetting() {
  const [royaltyDesignerList, setRoyaltyDesignerList] = useState<DesignerRyt[]>(
    []
  );
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const RoyaltyDesignerGridColumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "designer", headerName: "Designer Name", width: 300 },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable: false,
      width: 200,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              await updateRoyaltyActive(params.row, e.target.checked);
            }}
            color={"primary"}
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
        GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getDesignerRytResponse = await api.get<ApiResponse<DesignerRyt[]>>(
      "/Picklist/designer-ryts"
    );
    setRoyaltyDesignerList(getDesignerRytResponse?.data || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateRoyaltyActive = (row: DesignerRyt, isActive: boolean) => {
    row.isActive = isActive;
    try {
      api
        .put<ApiResponse<DesignerRyt>>("/Picklist/designer-ryt", row)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const deleteSubmit = () => {
    try {
      api
        .delete<ApiResponse<SessionDetails>>(
          `/Picklist/designer-ryt/${formik.values.id}`
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            setDeleteModalOpen(false);
            toast.success(response.message);
            formik.resetForm();
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      designer: "",
      isActive: true,
    },
    validationSchema: Yup.object({
      designer: Yup.string()
        .trim()
        .min(1, "Designer Name must be at least 1 characters")
        .max(40, "Designer Name must be at most 40 characters")
        .required("Designer Name is required")
        .test(
          "check-designer-duplicate",
          "Royalty Designer already exists",
          (value) => {
            return !royaltyDesignerList.some(
              (item) =>
                item.designer.trim().toLowerCase() ===
                  value.trim().toLowerCase() && item.id !== formik.values.id
            );
          }
        ),
    }),
    onSubmit: (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/designer-ryt", {
            id: values.id,
            designer: values.designer.trim(),
            isActive: JSON.parse(`${formik.values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              toast.success(response.message);
              formik.resetForm();
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/designer-ryt", {
            designer: values.designer,
            isActive: JSON.parse(`${formik.values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              toast.success(response.message);
              formik.resetForm();
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
          Add Royalty Designer
        </Button>}
        <RoyaltyDesignerModal
          isOpen={showAddUpdateModal}
          onClose={handleCloseModal}
          formik={formik}
          isButtonDisabled={disableSubmit}
        />
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"Royalty Designer Name"}
        />
      </div>
      <SettingGrid
        data={royaltyDesignerList}
        column={RoyaltyDesignerGridColumns}
        sortByField="id"
        sortByOrder="desc"
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
