import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import { AttachmentType } from "../../../models/AttachmentType";
import api from "../../../services/ApiService";
import { attachmentLevels } from "../../Products/EditItem/Constants";

interface StockCodeModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  formik: any;
}

const StockCodeModal = (props: StockCodeModalProps & { isButtonDisabled: boolean }) => {
  const { isOpen, onClose, formik, isButtonDisabled } = props;
  const [attachmentTypeList, setAttachmentTypeList] = useState<
    AttachmentType[]
  >([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getAttachmentResponse = await api.get<ApiResponse<AttachmentType[]>>(
      "/Picklist/attachment-types"
    );
    const stockCodeLevelAttachmentTypes = getAttachmentResponse?.data?.filter(at => at.lvl === attachmentLevels.SC);
    setAttachmentTypeList(stockCodeLevelAttachmentTypes || []);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogActions>
          <Button onClick={onClose}>x</Button>
        </DialogActions>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Select
                  margin="dense"
                  id="attachType"
                  name="attachType"
                  type="text"
                  error={
                    formik.touched.attachType &&
                    formik.errors.attachType
                  }
                  onBlur={formik.handleBlur}
                  fullWidth
                  value={formik.values.attachType || ""}
                  displayEmpty={true}
                  onChange={formik.handleChange}
                  style={{ marginTop: "8px" }}
                >
                  <MenuItem value="">Select Attachment Type</MenuItem>
                  {attachmentTypeList.map((at) => (
                    <MenuItem key={at.id} value={at.id}>
                      {at.attachType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="rank"
                  name="rank"
                  label={formik.errors.rank ? formik.errors.rank : "Enter Rank"}
                  type="number"
                  error={formik.touched.rank && formik.errors.rank}
                  onBlur={formik.handleBlur}
                  value={formik.values.rank}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" disabled={isButtonDisabled}>
              {formik.values.id > 0
                ? "Update Attachment Type"
                : "Add Attachment Type"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default StockCodeModal;
