import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const DeleteValidationModal = ({
    addModalOpen,
    handleCloseModal,
    deleteSubmit,
    fieldIdentifier,
}) => {
    return (
    <>
        <Dialog open={addModalOpen} onClose={handleCloseModal} >
            <DialogTitle>Confirm Delete Operation</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
            </IconButton>
            <DialogContent>                
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to delete this {fieldIdentifier}?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={deleteSubmit}>Yes</Button>
                <Button variant="outlined" onClick={handleCloseModal}>Cancel</Button>
            </DialogActions>
        </Dialog>
    
    </>);
        
};
export default DeleteValidationModal;