import Box from "@mui/material/Box";
import { DataGrid, 
  GridSortModel, 
  GridToolbarContainer, 
  GridToolbarQuickFilter 
} from "@mui/x-data-grid";
import { useState } from "react";
import CustomToolbar from "../CustomToolBar";
export const SettingGrid = ({
  data,
  column,
  sortByField = "",
  sortByOrder = null,
  rowSelectionOnClick = true,
  rowHeightGetter = null,
  columnVisibility = undefined
}) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: sortByField,
      sort: sortByOrder,
    },
  ]);

  return (
    <Box>
      <DataGrid
        sx={{
          height: "100%",
          width: "100%",
          marginTop: "10px",
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
        }}
        rows={data}
        columns={column}
        slots={{ toolbar: CustomToolbar }}
        // slotProps={{
        //   toolbar: gridToolbar,
        //   // toolbar: {
        //   //   showQuickFilter: true,
        //   // },
        // }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        pageSizeOptions={[25, 50, 100]}
        autoHeight
        sortingOrder={["asc", "desc"]}
        sortModel={sortModel}
        onSortModelChange={(sort) => setSortModel(sort)}
        disableRowSelectionOnClick={rowSelectionOnClick}
        getRowHeight={ rowHeightGetter || undefined  }
        columnVisibilityModel={columnVisibility}
      />
    </Box>
  );
};
