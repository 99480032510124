import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Checkbox,
  FormGroup,
  Typography,
  GlobalStyles,
} from "@mui/material";
import { FormModalDialogProps } from "../../../models/ModalDialogProps";
import { useEffect, useState } from "react";
import { PrjStatus } from "../../../models/PrjStatus";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { Tag } from "../../../models/Tag";

const PrjStageFormModal = (props: FormModalDialogProps & { isButtonDisabled: boolean }) => {
  const { isOpen, onClose, formik, isButtonDisabled } = props;
  const [statusList, setStatusList] = useState<PrjStatus[]>([]);
  const [tagList, setTagList] = useState<Tag[]>([]);

  useEffect(() => {
    fetchStatuses();
    fetchTags();
  }, [])

  const fetchStatuses = async () => {
    try {
      const resp = await api.get<ApiResponse<PrjStatus[]>>('/Picklist/statuses');
      if(resp?.isSuccess) {
        setStatusList(resp.data || []);
      }
    }catch(err) {}
  };

  const fetchTags = async () => {
    try {
      const resp = await api.get<ApiResponse<Tag[]>>('/Picklist/tags');
      if(resp?.isSuccess) {
        setTagList(resp.data || []);
      }
    }catch(err) {}
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
        <DialogActions>
          <Button onClick={onClose}>x</Button>
        </DialogActions>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="description"
                  name="description"
                  label={
                    (formik.touched.description && formik.errors.description) ||
                    "Enter Description"
                  }
                  type="text"
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    !!(formik.touched.description && formik.errors.description)
                  }
                  onBlur={formik.handleBlur}
                />
              {/* <Grid item xs={4}>
                <TextField
                  margin="dense"
                  id="family"
                  name="family"
                  label={
                    (formik.touched.family && formik.errors.family) ||
                    "Enter Family"
                  }
                  type="text"
                  fullWidth
                  value={formik.values.family}
                  onChange={formik.handleChange}
                  error={!!(formik.touched.family && formik.errors.family)}
                  onBlur={formik.handleBlur}
                /> 
              </Grid>*/}
              { (formik.touched.statuses && formik.errors.statuses) ? 
                <Typography component="h4" color="error">Select at least one Status for this Stage</Typography> : 
                <h4>Assign Statuses to Stage</h4>}
            <FormGroup>
              <div
                style = {{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  maxHeight: "300px",
                  overflowX:"auto",
                  }}
              >                
              {
                statusList.filter(prjStatus => prjStatus.isActive !== false)
                  .map((prjStatus, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.statuses?.some(st => st.id == prjStatus.id && prjStatus.isActive !== false)}
                        onChange={() => {
                          const statuses = formik.values.statuses;

                          if (statuses?.some(st => st.id == prjStatus.id)) {
                            // If status is already selected, remove it
                            const updatedStatusList = statuses.filter(
                              (st) => st.id !== prjStatus.id
                            );
                            formik.setFieldValue("statuses", updatedStatusList);
                          } else {
                            // If status is not selected, add it
                            formik.setFieldValue("statuses", [
                              ...statuses,
                              prjStatus,
                            ]);
                          }
                        }}
                        key={`statuses.${prjStatus.id}`}
                        name="statuses"
                      />
                    }
                    label={prjStatus.description}
                  />
                ))
              }
              </div>
              { (formik.touched.tags && formik.errors.tags) ? 
                <Typography component="h4" color="error">Select at least one Tags for this Stage</Typography> : 
                <h4>Assign Tags to Stage</h4>}
              <div
                style = {{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  maxHeight: "300px",
                  overflowX:"auto",
                  }}
              >                
              {
                tagList.filter(tag => tag.isActive !== false)
                  .map((tag, index) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.tags?.some(t => t.id == tag.id && tag.isActive !== false)}
                        onChange={() => {
                          const tags = formik.values.tags;

                          if (tags?.some(t => t.id == tag.id)) {
                            // If status is already selected, remove it
                            const updatedTagList = tags.filter(
                              (t) => t.id !== tag.id
                            );
                            formik.setFieldValue("tags", updatedTagList);
                          } else {
                            // If status is not selected, add it
                            formik.setFieldValue("tags", [
                              ...tags,
                              tag,
                            ]);
                          }
                        }}
                        key={`tags.${tag.id}`}
                        name="tags"
                      />
                    }
                    label={tag.name}
                  />
                ))
              }
              </div>
              </FormGroup>
            <h4>Set Active</h4>
            <RadioGroup
              row
              name="isActive"
              value={formik.values.isActive}
              onChange={(event) => formik.handleChange(event)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" disabled={isButtonDisabled}>
              {formik.values.id > 0
                ? "Update Project Stage"
                : "Add Project Stage"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default PrjStageFormModal;
