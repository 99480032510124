import { useEffect, useState } from "react";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import {
  GRID_ACTION_EDIT_ICON,
} from "../../../components/GridUtilityComponents";
import { useFormik } from "formik";
import * as Yup from "yup";
import SecurityGroupFormModal from "./SecurityGroupModal";
import GridCellChipsContent from "../../../components/GridCellChipsContent";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { SecurityGroup } from "../../../models/SecurityGroup";
import SessionService from "../../../services/SessionService";
import { Permissions } from "../../../models/Enum";

export default function () {
  const [securityGroupList, setSecurityGroupList] = useState<SecurityGroup[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const formDefaultValue = {
    id: 0,
    groupName: "",
    groupDescription: "",
    isActive: true,
    securityGroupFields: [],
  };

  const sessionService = SessionService.getInstance();
  const manageAssignmentsPermission = sessionService.hasPermission(Permissions.ManageAssignments);

  const SecurityGroupGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 80 },
    { field: "groupName", headerName: "Description", width: 150 },
    {
      field: "securityGroupFields",
      headerName: "Fields",
      type: "Array",
      width: 850,
      sortable: false,
      renderCell: (params: any) => {
        if (!params.value) {
          return params.value;
        } else {
          return (
            <GridCellChipsContent cellData={params.row.securityGroupFields} keyField="id" displayTextFiled="fieldName" />
          );
        }
      },
    },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable: false,
      renderCell: (params: any) => (
        <Switch
          checked={params.value}
          onChange={(e) => {
            params.api.setEditCellValue(
              { id: params.id, field: params.field },
              e.target.checked
            );
            updateSecurityGroupActive(params.row, e.target.checked);
          }}
          color="primary"
          inputProps={{ "aria-label": "controlled" }}
          disabled={!manageAssignmentsPermission}
        />
      ),
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 100,
      valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClick),
      ],
    },
  ];

  const handleEditClick = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClick = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    fetchAssignmentList();
  }, []);

  const fetchAssignmentList = async () => {
    const resp = await api.get<ApiResponse<SecurityGroup[]>>(
      "/Assignment/security-group"
    );

    const sortedList = resp?.data?.sort((rec1, rec2) => rec2.id - rec1.id);
    setSecurityGroupList(sortedList || []);
  };

  const updateSecurityGroupActive = (
    group: SecurityGroup,
    active: boolean
  ) => {
    const data = {
      ...group,
      isActive: active,
    };

    api
      .put<ApiResponse<boolean>>("/Assignment/security-group", data)
      .then(async (response: any) => {
        if (response.isSuccess) {
          toast.success(response.message);
          await fetchAssignmentList();
        } else {
          throw new Error(response.message);
        }
      })
      .catch((error) => {
        console.error(error?.message);
      });
  };

  const formik = useFormik({
    initialValues: formDefaultValue,
    validateOnChange: false,
    validationSchema: Yup.object({
      groupName: Yup.string()
        .trim()
        .min(1, "Description must be at least 1 characters")
        .max(100, "Description must be at most 40 characters")
        .required("Description is Required")
        .test(
          "check-name-duplicate",
          "Security Group with this name already exists",
          function async(value) {
            return new Promise((resolve) => {
              const isAssignmentNameDuplicate = securityGroupList.some(
                (item) => item.groupName.toLowerCase() === value.toLowerCase() && item.id !== formik.values.id
              );
              isAssignmentNameDuplicate ? resolve(false) : resolve(true);
            });
          }
        ),
      securityGroupFields: Yup.array().min(1, "Please select at least 1 field for this security group"),
    }),
    onSubmit: async (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Assignment/security-group", {
            id: values.id,
            groupName: values.groupName.trim(),
            groupDescription: values.groupName.trim(),
            isActive: JSON.parse(`${values.isActive}`),
            securityGroupFields: values.securityGroupFields
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchAssignmentList();
            } else {
              throw new Error(response.message);
            }
          })
          .catch((error) => {
            console.error(error?.message);
          });
      } else {
        api
          .post<ApiResponse<number>>("/Assignment/security-group", {
            groupName: values.groupName.trim(),
            groupDescription: values.groupName.trim(),
            isActive: JSON.parse(`${values.isActive}`),
            fields: values.securityGroupFields?.map(st => st.fieldName),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchAssignmentList();
            } else {
              throw new Error(response.message);
            }
          })
          .catch((error) => {
            console.error(error?.message);
          });
      }
    },
  });

  return (
    <>
      {showAddUpdateModal && <SecurityGroupFormModal
        isOpen={showAddUpdateModal}
        onClose={handleCloseModal}
        formik={formik}
        isButtonDisabled={disableSubmit}
      />}

      {manageAssignmentsPermission &&
        <Button variant="contained" onClick={handleAddClick}>
          Add Security Group
        </Button>}

      <SettingGrid
        data={securityGroupList}
        column={SecurityGroupGridCollumns}
        sortByField="id"
        sortByOrder={"desc"}
        rowHeightGetter={() => 'auto'}
        columnVisibility={{
          actions: manageAssignmentsPermission,
        }}
      />
    </>
  );
}

