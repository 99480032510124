import { 
    Button,
    Checkbox,
    Dialog, 
    DialogActions, 
    DialogContent,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import { ApiResponse } from "../../../models/ApiResponse";
import api from "../../../services/ApiService";
import { DataFieldDetail} from "../../../models/DataFieldDetail";
import { useEffect, useState } from "react";
import { CheckBox } from "@mui/icons-material";
import { DataTypeDetail } from "../../../models/DataTypeDetail";

interface DataFieldTypeModalProps {
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;
};

const DataFieldTypeModal = (props: DataFieldTypeModalProps & { isButtonDisabled: boolean }) => {
    const [options, setOptions] = useState<DataTypeDetail[]>([]);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        getDataTypeList();
    },[])
    
    const getDataTypeList = async () => {
        const getDataTypeResponse = await api.get<
        ApiResponse<DataTypeDetail[]>
        >("/Picklist/data-types");

        const sortedOptions = getDataTypeResponse?.data?.sort((a, b) => {
            if (a.description < b.description) {
                return -1;
            }
            if (a.description > b.description) {
                return 1;
            }
            return 0;
        }) || [];
        setOptions(sortedOptions);
    };

    //search bar and check if the value is not false if false then do not show in the render check box
    const searchOptions = options.filter(fieldType => 
        fieldType.description.trim().toLowerCase().includes(searchInput.trim().toLowerCase()) 
        && fieldType.isActive !== false     
    );
    
    const renderCheckBox = () => {
        return searchOptions.map((fieldType, index) => (
            <div key = {index}>
                <FormControlLabel
                       control={
                        <Checkbox
                          checked={formik.values.types.some(t => t.id === fieldType.id)}
                          onChange={(e) => {
                            const types = formik.values.types;
                            const typeId = fieldType.id;
            
                            if (types?.some(t => t.id === fieldType.id)) {
                              const updatedTypes = types.filter((t) => t.id !== typeId); // slice off the value from array
                              formik.setFieldValue("types", updatedTypes);  //update formik with new list of types that doesn't have the unchecked type
                            } else {
                              formik.setFieldValue("types", [...types, fieldType]);
                            }
                          }}
                          name={`types.${fieldType.id}`}
                        />                      
                        }
                      label={fieldType.description}          
                />
            </div>    
        ));
    };
    
    const {isOpen, onClose, formik, isButtonDisabled} = props;
    return(
        <Dialog open = {isOpen} onClose = {onClose} fullWidth maxWidth="xl">
            <DialogActions>
                <Button onClick = {onClose}>x</Button>
            </DialogActions>
            <form onSubmit = {formik.handleSubmit}>
            <DialogContent>
                <TextField
                    autoFocus
                    id = "field"
                    name = "field"
                    label = {
                        formik.touched.field && formik.errors.field ?
                        formik.errors.field :"Enter Data Field"
                    }
                    type = "text"
                    fullWidth
                    value = {formik.values.field}
                    onChange = {formik.handleChange}
                    error = {formik.touched.field && formik.errors.field}
                    onBlur = {formik.handleBlur}
                />
                <h4>Assign Data Types to Data Field</h4>
                <FormGroup>
                    <div>
                        <TextField
                            fullWidth
                            label = "Search Data Types"
                            value = {searchInput}
                            onChange = {(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                    {formik.touched.types && Boolean(formik.errors.types) ?
                        <Typography component = "h4" color = "error">{formik.errors.types}</Typography> : ""
                        //<Typography component = "h4" color = "error">Select at least one Data Type for this Data Field</Typography>: ""
                    }
                    <div style = {{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "wrap",
                        maxHeight: "400px",
                        overflowY:"auto",
                        }}
                    >
                        {renderCheckBox()}
                    </div>
                </FormGroup>
                <h4>Set Active</h4>
                <RadioGroup
                    row
                    name = "isActive"
                    value = {formik.values.isActive}
                    onChange = {(event) => formik.handleChange(event)}
                >
                    <FormControlLabel value = {true} control = {<Radio/>} label = "Active"/>
                    <FormControlLabel value = {false} control = {<Radio/>} label = "Inactive"/>
                </RadioGroup>
                <DialogActions>
                    <Button variant="contained" type = "submit" disabled={isButtonDisabled}>
                        {formik.values.id > 0 ?
                        "Update Data Field" :
                        "Add Data Field"}
                    </Button>
                </DialogActions>
            </DialogContent>
            </form>
        </Dialog>
    )
};

export default DataFieldTypeModal;