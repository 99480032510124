import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from "../models/Enum";
import { QuoteListRequest } from "../models/Quotes/QuoteListRequest";

interface QuotesState {
    quoteListRequest: QuoteListRequest;
};

const initialQuoteListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
};

const initialState: QuotesState = {
    quoteListRequest: initialQuoteListRequest,
};

const quotes = createSlice({
    name: 'quotes',
    initialState,
    reducers: {
      updateQuoteListRequest(state, action: PayloadAction<QuoteListRequest>) {
        state.quoteListRequest = action.payload;
      },
    },
  });
  
  export const { updateQuoteListRequest } = quotes.actions;
  
  export default quotes.reducer;