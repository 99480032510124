import React from 'react';
import { Box, Select, MenuItem, FormControl, Typography, Pagination as MuiPagination, PaginationItem as MuiPaginationItem } from '@mui/material';

interface PaginationProps {
  pageCount: number;
  page: number;
  pageSize: number;
  rowsPerPageOptions: number[];
  totalRows: number;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  onRowsPerPageChange: (event: any) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  page,
  pageSize,
  rowsPerPageOptions,
  totalRows,
  onPageChange,
  onRowsPerPageChange
}) => {

  const start = page * pageSize + 1;
  const end = Math.min((page + 1) * pageSize, totalRows);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
      <Typography variant="body2">
        Showing <b>{start}-{end}</b> from <b>{totalRows}</b> items
      </Typography>
      <MuiPagination
        count={pageCount}
        page={page + 1}
        onChange={onPageChange}
        siblingCount={1}
        boundaryCount={0}
        color="primary"
        showFirstButton={false}
        showLastButton={false}
        renderItem={(item) => (
          item.page ? (
            <MuiPaginationItem {...item} />
          ) : null
        )}
      />
      <FormControl variant="outlined" style={{ display: "inline" }}>
        <label style={{ marginRight: "12px" }}>Rows per page</label>
        <Select
          value={pageSize}
          onChange={onRowsPerPageChange}
          sx={{ width: "80px" }}
        >
          {rowsPerPageOptions?.length > 0 &&
            rowsPerPageOptions.map((pageSizeOption, index) => (
              <MenuItem key={index + 1} value={pageSizeOption}>{pageSizeOption}</MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Pagination;
