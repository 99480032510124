import { useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Sidebar from "./Sidebar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { AppBar, Button, Link } from "@mui/material";
import SessionService from "../services/SessionService";
import { User } from "../models/User";
import { CSSDefaults, ROUTES_WITH_PERMISSIONS } from "../models/GlobalConstants";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Notifications from "./Notifications";
import { useLocation } from "react-router-dom";
import api from "../services/ApiService";
import { ApiResponse } from "../models/ApiResponse";
import { SpecialTag } from "../models/SpecialTag";
import { SpecialTags } from "../models/Enum";
import { useAppDispatch, useAppSelector } from "../store";
import { setDashboardViewReviewRequiredTagId } from "../reducers/filtersReducer";
import { updateDashboardListRequest } from "../reducers/itemsReducer";
import ManageNotification from "./ManageNotification";

type Props = {
  children?: React.ReactNode;
};

// Augment the palette to include a salmon color
declare module '@mui/material/styles' {
  interface Palette {
    purpleHues: Palette['primary'];
    greenHues: Palette['primary'];
  }

  interface PaletteOptions {
    purpleHues?: PaletteOptions['primary'];
    greenHues?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include a salmon option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    purpleHues: true;
    greenHues: true;
  }
}

let defaultTheme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

defaultTheme = createTheme({
  palette: {
    primary: {
      main: CSSDefaults.primaryColor,
    },
    secondary: {
      main: CSSDefaults.primaryColor,
    },
    purpleHues: defaultTheme.palette.augmentColor({
      color: {
        main: CSSDefaults.purpleHues1,
      },
      name: 'purpleHues1',
    }),
    greenHues: defaultTheme.palette.augmentColor({
      color: {
        main: CSSDefaults.greenHues1,
        light: CSSDefaults.greenHues2,
      },
      name: 'greenHues1',
    })
  },
  typography: {
    h6: { fontSize: "1rem" },
    allVariants: {
      color: "#000",
    },
  },
});

// Defined the drawer width for different breakpoints
const drawerWidth = {
  default: 340,
  tablet: 265,
};

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.visualcomfort.com/">
        Visual Comfort
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// Styled component for Drawer
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth.default,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
      },
    }),
    [theme.breakpoints.between('sm', 'md')]: {
      width: drawerWidth.tablet,
      ...(!open && {
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: `calc(${theme.spacing(7)} + 1px)`,
        },
      }),
    },
  },
}));

export const PageLayout: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [user, setUser] = useState<User>();
  const [breadCrumbText, setBreadCrumbText] = useState("");
  const location = useLocation(); 
  const dispatch = useAppDispatch();

  const sessionService = SessionService.getInstance();
  const dashboardListRequest = useAppSelector((state) => state.items.dashboardListRequest);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logout = () => {
    sessionService.clearSession();
    sessionService.clearReduxState();
    window.location.replace("/");
  };

  useEffect(() => {
    const user = sessionService.getCurrentUser();
    setUser(user);
    fetchSpecialTags();
  }, []);

  const fetchSpecialTags = async () => {
    try {
      api
        .get<ApiResponse<SpecialTag[]>>(`/PickList/special-tags`)
        .then((response) => {
          if (response.isSuccess) {
              const findReviewRequired = response?.data?.find((st) => st.tagKey === SpecialTags.ReviewRequired);
              if(findReviewRequired) {
                dispatch(setDashboardViewReviewRequiredTagId(findReviewRequired.tagId));
                dispatch(updateDashboardListRequest({
                  ...dashboardListRequest,
                  tags: [findReviewRequired.tagId]
                }));
              }
          }
        })
        .catch((error) => {
          console.error("Exception from special tags", error);
        });
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  const onActiveMenuChange = (activeParentMenu, activeSubMenu) => {
    let AppBarTitle = `${activeParentMenu.title}${activeSubMenu ? " > " + activeSubMenu.title : ""}`;
    const permissionPath = !ROUTES_WITH_PERMISSIONS.some(({path, permission}) => {
      return !sessionService.hasPermission(permission) && location.pathname === path
    });
    setBreadCrumbText(permissionPath? AppBarTitle: " ");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <div style={{ width: "265px" }}>
              <Link href="/">
                <img
                  src="/logo.png"
                  alt="Visual Comfort"
                  className="img-fluid"
                  style={{ width: "100%", marginTop: "10px" }}
                />
              </Link>
              <h4 style={{marginTop: "5px", marginLeft: "5px"}}>LEAGO</h4>
            </div>
            <IconButton onClick={toggleDrawer}>
              { open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon /> }
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <Sidebar onActiveMenuChange={onActiveMenuChange} open={open} />
          </List>
        </Drawer>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <AppBar
            position="sticky"
            style={{
              background: CSSDefaults.headerBgColor,
              color: CSSDefaults.headerFontColor,
              display: "block",
            }}
          >
            <Toolbar
              sx={{
                pr: "15px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {breadCrumbText}
              </Typography>
              <ManageNotification />
              <Notifications />
              <IconButton title="Logout" onClick={logout}>
                <LogoutOutlinedIcon sx={{ color: "#484848", mr: 2 }} />
              </IconButton>

              <Typography component="h6" variant="h6" textAlign="right" noWrap>
                {user?.name} <br />
                {user?.email}
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth={"xl"} sx={{ mt: 2, mb: 2 }}>
              {children}
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </div>
      </Box>
    </ThemeProvider>
  );
};
