import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ApiResponse } from "../models/ApiResponse";
import { ProductStageCheckListDetail } from "../models/ProductStageCheckListDetail";
import api from "../services/ApiService";
import { FormControl } from "@mui/base";
import ToggleSwitchButton from "./ToggleSwitchButton";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../utils/constants";
import { Permissions } from "../models/Enum";
import SessionService from "../services/SessionService";
import { useParams } from "react-router-dom";

const StageCheckList = ({ checkListStatus, setCheckListStatus }) => {

  const sessionService = SessionService.getInstance();
  const { editItemId } = useParams();

  const [stageChecklistFields, setStageChecklistFields] = useState<ProductStageCheckListDetail[]>([]);
  const [accordianExpanded, setAccordianExpanded] = useState<boolean>(false);

  useEffect(() => {
    fetchChecklist();
  }, []);

  /**
   * This function is used to fetch stage check list data and set it to state
   */
  const fetchChecklist = async () => {
    const sessionInfo: any = JSON.parse(
      window?.localStorage.getItem("session") || "{}"
    );
    try {
      const response = await api
        .get<ApiResponse<ProductStageCheckListDetail[]>>(`/CheckList/list/${editItemId}`);
      if (response.isSuccess) {
        let { data } = response;
        setStageChecklistFields(data);
      } else {
        throw new Error(response?.message);
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  };

  const handleAccordionChange = () => {
    setAccordianExpanded((prev) => !prev);
  };

  const checkManageCheckListFieldPermission = () => {
    return !sessionService.hasPermission(Permissions.ManageStageAdvancementCheckList);
  };

  /**
   * This function is used to render toogle switch button field with timestamp label
   * @param checkListDetail - Stage Checklist Field Detail
   */
  const renderToggleButton = (checkListDetail: ProductStageCheckListDetail) => (
    <FormControl key={checkListDetail.stageCheckListId} style={{ margin: '5px 0', width: '100%' }}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <Typography style={{ textTransform: 'capitalize' }}>
            {checkListDetail.friendlyName}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ToggleSwitchButton
            checked={checkListDetail.status}
            onChange={(e) => handleToggleChange(e, checkListDetail)}
            fieldName={checkListDetail.checkListItemName}
            readOnlyField={checkManageCheckListFieldPermission()}
          />
        </Grid>
        <Grid item xs={3}>
          {checkListDetail.lastUpdatedDate && (
            <Typography>
              {moment(checkListDetail.lastUpdatedDate).format(DATE_TIME_FORMAT)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </FormControl>
  );

  /**
   * This function is used to handle toggle button change
   * @param e - Event object of Toggle switch button
   * @param checkListDetail - Stage Checklist Field Detail
   */
  const handleToggleChange = (e, checkListDetail) => {
    const updatedFieldStatus = stageChecklistFields.map((dataItem) =>
      dataItem.stageCheckListId === checkListDetail.stageCheckListId
        ? { ...dataItem, status: e.target.checked }
        : dataItem
    );
    setStageChecklistFields(updatedFieldStatus);

    // Find the updated checklist field and set it to checkListStatus state to submit it to API
    const findUpdatedFieldStatus = updatedFieldStatus.find(
      (dataItem) => dataItem.stageCheckListId === checkListDetail.stageCheckListId
    );
    if (findUpdatedFieldStatus) {
      const updatedProductStageCheckListStatus = [
        ...checkListStatus.filter(
          (listItem) => listItem.stageCheckListId !== findUpdatedFieldStatus.stageCheckListId
        ),
        {
          id: findUpdatedFieldStatus.id,
          stageCheckListId: findUpdatedFieldStatus.stageCheckListId,
          status: findUpdatedFieldStatus.status,
        },
      ];
      setCheckListStatus(updatedProductStageCheckListStatus);
    }
  };

  /**
   * This function is used to render checklist fields with category section label
   */
  const renderChecklist = () => {
    const groupedData = stageChecklistFields.reduce((acc, cl) => {
      if (!acc[cl.stageDisplayText]) {
        acc[cl.stageDisplayText] = [];
      }
      acc[cl.stageDisplayText].push(cl);
      return acc;
    }, {});

    return Object.keys(groupedData).map((stage) => (
      <div key={stage} style={{ marginBottom: '20px' }}>
        <Typography variant="h6" style={{ marginBottom: '10px' }}>{stage}</Typography>
        {groupedData[stage].sort((a, b) => a.sequenceNum - b.sequenceNum).map((cl) => (
          <div key={cl.stageCheckListId} style={{ marginBottom: '5px' }}>
            {renderToggleButton(cl)}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <Accordion
      expanded={accordianExpanded}
      onChange={handleAccordionChange}
      sx={{
        border: "1px solid",
        borderRadius: "4px",
        padding: "15px",
        margin: "20px 0"
      }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls={`panel-group-content`}
        className={accordianExpanded && "active-accordian-expanded"}
      >
        <Typography
          style={{ fontWeight: accordianExpanded ? "bold" : "normal" }}
        >
          Stage CheckList
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{marginTop: '8px'}}>
        {stageChecklistFields?.length > 0 && renderChecklist()}
      </AccordionDetails>
    </Accordion>
  )
}

export default StageCheckList;