import { 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";

interface RoyaltyDesignerModalProps{
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;
};

const RoyaltyDesignerModal = (props:RoyaltyDesignerModalProps & { isButtonDisabled: boolean }) => {
    const {isOpen, onClose, formik, isButtonDisabled} = props;

    return(
    <>
        <Dialog open = {isOpen} onClose = {onClose} fullWidth>
            <DialogActions>
                <Button onClick = {onClose}>x</Button>
            </DialogActions>
            <form onSubmit = {formik.handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        id = "designer"
                        name = "designer"
                        margin = "dense"
                        label = {
                            formik.touched.designer && formik.errors.designer
                            ? formik.errors.designer :
                            "Enter Royalty Deisgner Name"
                        }
                        fullWidth
                        type = "text"
                        value = {formik.values.designer}
                        onChange = {formik.handleChange}
                        error = {formik.touched.designer && formik.errors.designer}
                        onBlur = {formik.handleBlur}
                    />
                    <h4>Set Active</h4>
                    <RadioGroup
                        row
                        name = "isActive"
                        value = {formik.values.isActive}
                        onChange = {(event) => formik.handleChange(event)}
                    >
                        <FormControlLabel value = {true} control = {<Radio/>} label = "Active"/>
                        <FormControlLabel value = {false} control = {<Radio/>} label = "Inactive"/>
                    </RadioGroup>
                    <DialogActions>
                        <Button variant = "contained" type = "submit" disabled={isButtonDisabled}>
                            {formik.values.id > 0 ?
                            "Update Royalty Designer" : "Add Royalty Designer" }
                        </Button>
                    </DialogActions>
                </DialogContent>
            </form>
        </Dialog>
    </>
    )
}

export default RoyaltyDesignerModal;