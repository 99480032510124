import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  FormControlLabel,
  Grid,
  DialogTitle,
  FormControl,
  IconButton,
  Autocomplete,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { FormModalDialogProps } from "../models/ModalDialogProps";
import { useEffect, useState } from "react";
import { dataFieldNames, FIELD_NAMES, DEVELOPMENT_TYPE_DROPDOWN_OPTIONS, STOCKING_TYPE_HIDDEN_OPTIONS_FOR_BOM_COMPONENT, BOM_COMPONENT_DEVELOPMENT_TYPE_DROPDOWN_OPTIONS, checkDecimalNumberValidation } from "../pages/Products/EditItem/Constants";
import { useFormik } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { ProductLayoutFieldDetail } from "../models/Items/ProductLayoutFieldDetail";
import { ProductLayoutCategoryDetail } from "../models/Items/ProductLayoutCategoryDetail";
import ToggleSwitchButton from "./ToggleSwitchButton";
import { EditItemDropdownOptions } from "../models/Items/EditItemDropdownOptions";
import { CreateBOMComponentRequest } from "../models/Items/CreateBOMComponentRequest";
import * as Yup from "yup";
import { KeyValues } from "../models/Items/FilterOptions";
import { ApiResponse } from "../models/ApiResponse";
import api from "../services/ApiService";
import { DATE_FORMAT } from "../utils/constants";
import { toast } from "react-toastify";

const CreateBOMComponentModal = (props: FormModalDialogProps & {
  isButtonDisabled: boolean,
  layoutFields: ProductLayoutCategoryDetail[],
  dropDownOptions: EditItemDropdownOptions
}) => {
  const { isOpen, onClose, layoutFields, dropDownOptions } = props;
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [fieldsDisabled, setFieldsDisabled] = useState<string[]>([FIELD_NAMES.function]);
  const [selectedDropDownOptions, setSelectedDropDownOptions] = useState<any>(
    {}
  );
  const [productLayoutFields, setProductLayoutFields] = useState<ProductLayoutFieldDetail[]>([]);
  const [functionDropDownOptions, setFunctionDropDownOptions]= useState<KeyValues[]>([]);

  // these fields stores the reference id and not the actual value e.g. Function, Marketing Designer
  const REFERENCE_FIELDS = [
    "warehouse",
    "marketingDesigner",
    "royaltyDesigner",
    "itemStockingType",
    "skuRank",
    "bcRank",
    "style",
    "lightSource",
    "category",
    "function",
    "skuType",
    "developmentType",
    "vendor",
    "prop65"
  ];

  const initialValues: CreateBOMComponentRequest = {
    id: 0,
    baseCode: "",
    stockcode: "",
    longDesc: "",
    slIntroDate: null,
    vendor: null,
    vendorDisplayText: "",
    warehouse: null,
    inventoryItem: false,
    sellingItem: false,
    purchasingItem: false,
    category: null,
    function: null,
    marketingDesigner: null,
    royaltyDesigner: null,
    skuType: null,
    itemStockingType: null,
    itemVendorCost: null,
    skuRank: null,
    bcRank: null,
    commission: false,
    royalty: false,
    style: null,
    groupName: "",
    shortDesc: "",
    htsMaterial: "",
    htsMaterialDtl: "",
    pdCode: "",
    lightSource: null,
    iS_220VNotAvailable: false,
    prop65: null,
    prop65DisplayText: "",
    developmentType: BOM_COMPONENT_DEVELOPMENT_TYPE_DROPDOWN_OPTIONS[0].name
  };

  const validations = Yup.object().shape({
    stockcode: Yup.string()
      .trim()
      .required("Stock Code is required")
      .min(1, "Stock Code must be at least 1 character")
      .max(50, "Stock Code must be at most 50 characters"),
    longDesc: Yup.string()
      .trim()
      .required("Description is required")
      .min(1, "Description must be at least 1 character")
      .max(50, "Description must be at most 200 characters"),
    itemStockingType: Yup.string().trim().nullable().required("Stocking / SL Type is required"),
    itemVendorCost: checkDecimalNumberValidation("Vendor Cost")
  });

  useEffect(()=> {
    resetModalForm();
  }, [isOpen]);

  useEffect(() => {
    prepareLayoutFields();
  }, [layoutFields]);

  /**
   * This function is used to fetch edit product layout fields from api and set it to state
   */
  const prepareLayoutFields = async () => {
    if (layoutFields?.length > 0) {
      // Flatten fields array from the api response data
      let flattenedFields = layoutFields.flatMap(category => category.fields);

      const prepareLayoutFields = [];
      // Update fields names recieved from api so that it matches with formRenderer keys to avoid casing errors
      flattenedFields.map((field) => {
        const matchedName = formRendererNames.find(
          (fn) => fn.toLowerCase() === field.fieldName.toLowerCase()
        );

        if (matchedName) {
          prepareLayoutFields.push({
            ...field,
            fieldName: matchedName
          });
        }
      });
      setProductLayoutFields(prepareLayoutFields);
    }
  };

  const getFormattedDate = (fieldName: string) => {
    return formik.values[fieldName]
      ? moment(formik.values[fieldName]).format(DATE_FORMAT)
      : null
  };

  const resetModalForm = () => {
    formik.resetForm();
    formik.setErrors({});
    setSelectedDropDownOptions({});
    setFunctionDropDownOptions([]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      try {
        const data = {
          ...values,
          developmentType: BOM_COMPONENT_DEVELOPMENT_TYPE_DROPDOWN_OPTIONS[0].id,
          slIntroDate: getFormattedDate(FIELD_NAMES.slIntroDate),
        };

        setDisableSubmit(true);
        const createBOMComponentResponse = await api.post<ApiResponse<CreateBOMComponentRequest>>(
          "/component/add",
          data
        );
        if (createBOMComponentResponse?.isSuccess) {
          toast.success(createBOMComponentResponse?.message);
          setDisableSubmit(false);
          onClose();
        }
      }
      catch (error: any) {
        setDisableSubmit(false);
        console.error("Exception from Add BOM Component", error);
      }
    }
  });

  /**
   * This function is used to handle Autocomplete dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
   */
  const handleAutocompleteOptionsChange = (fieldName, selectedItem) => {
    if (!selectedItem) {
      formik.setFieldValue(fieldName, undefined);
        setSelectedDropDownOptions({
          ...selectedDropDownOptions,
          [fieldName]: undefined
        });
    } else {
      /* 
        selectedValue variable logic: 
        if dropdown option contains string type value, then string value will be selected.
        else if Reference fields array includes dropdown field name, then id will be selected.
        else name will be selected.
      */
      const selectedValue =
        typeof selectedItem === "string"
          ? selectedItem
          : REFERENCE_FIELDS.includes(fieldName)
            ? selectedItem.id
            : selectedItem.name || selectedItem.description;
      formik.setFieldValue(fieldName, selectedValue);
      setSelectedDropDownOptions({
        ...selectedDropDownOptions,
        [fieldName]: selectedItem
      });
    }
  };

  /**
     * This function is used to remove array product from state and enable the dropdown field
     * @param fieldName - Name of the field to enable it
     */
  const handleDropDownFieldsEnabled = (fieldName) => {
    const isFieldExists = fieldsDisabled.includes(fieldName);

    if (isFieldExists) {
      // Remove the product from the array
      setFieldsDisabled(fieldsDisabled.filter(sec => sec !== fieldName));
    }
  };

  /**
   * This function is used to returns list of DataTypes for a given field name
   * @param categoryName - Category display text field api data
   */
  const prepareFunctionDropDownOptions = (categoryId: number) => {
    if (categoryId && dropDownOptions?.productFunctions?.length) {
      const functionDropDownOptions = dropDownOptions.productFunctions
        .filter(func => func.category == categoryId)
        .map(item => {
          return {
            id: item.id,
            description: item.description
          }
        });
      setFunctionDropDownOptions(functionDropDownOptions);
    }
  };

  /**
   * This function is used to handle Select dropdown value
   * @param fieldName - Name of the field
   * @param selectedItem - Selected option value
   */
  const handleDropDownOptionsChange = (fieldName, selectedItem) => {
    const selectedValue =
      typeof selectedItem === "string"
        ? selectedItem
        : REFERENCE_FIELDS.includes(fieldName)
          ? selectedItem.value
          : selectedItem.children;
    formik.setFieldValue(fieldName, selectedValue);

    if (fieldName === FIELD_NAMES.category) {
      formik.setFieldValue(FIELD_NAMES.function, undefined);
      handleDropDownFieldsEnabled(FIELD_NAMES.function);
      prepareFunctionDropDownOptions(selectedItem.value);
      
    }
  };

  /**
   * This function is used to render autocomplete with dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   * @param optionLabelGetter - Custom label for dropdown option list
   */
  const renderAutocomplete = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined,
    optionLabelGetter: (option) => string = null
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize", marginBottom: "5px" }}
      >
        {label}
        {(fieldName === FIELD_NAMES.itemStockingType) && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <Autocomplete
        id={`outlined-${fieldName}`}
        value={
          selectedDropDownOptions[fieldName]
            ? selectedDropDownOptions[fieldName]
            : null
        }
        onChange={(event, newValue) =>
          handleAutocompleteOptionsChange(fieldName, newValue)
        }
        onBlur={formik.handleBlur}
        options={optionList ? optionList : []}
        getOptionLabel={(option) =>
          optionLabelGetter ? optionLabelGetter(option) : option.name || option.description || option
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            error={
              Boolean(formik.errors[fieldName])
            }
            label={formik.errors[fieldName] ? String(formik.errors[fieldName]) : ""}
            key={params.id}
            {...params}
            placeholder="- Select -"
          />
        )}
      />
    </FormControl>
  );

  /**
   * This function is used to render select dropdown option list
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param optionList - Dropdown option array list
   */
  const renderDropDown = (
    fieldName: string = "",
    label: string = "",
    optionList: Array<any> | undefined
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize", marginBottom: "5px" }}
      >
        {label}
      </label>
      <Select
        id={`outlined-${fieldName}`}
        name={fieldName}
        type="text"
        onBlur={formik.handleBlur}
        value={formik.values[fieldName] || ""}
        displayEmpty={true}
        onChange={(event, selectedItem: any) => {
          handleDropDownOptionsChange(fieldName, selectedItem.props)
        }}
        disabled={fieldsDisabled.includes(fieldName)}
        sx={{
          backgroundColor: (fieldsDisabled.includes(fieldName)) ? "#EBEBE4" : "#FFFFFF"
        }}
      >
        <MenuItem value="" disabled>Select</MenuItem>
        {optionList && optionList.length > 0 &&
          optionList.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {/* since item type is any, this is required to get the data field out */}
              {item.description || item.name || item.bomDescription}
            </MenuItem>
          ))}
        <MenuItem value={null}>None</MenuItem>
      </Select>
    </FormControl>
  );

  /**
   * This function is used to render date picker field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderDatePicker = (
    fieldName: string = "",
    label: string = "",
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
      htmlFor={`outlined-${fieldName}`}
      style={{ textTransform: "capitalize", marginBottom: "5px" }}
      >
        {label}
      </label>
      <TextField
        id={`outlined-${fieldName}`}
        type="date"
        name={fieldName}
        value={moment(formik.values[fieldName]).format("YYYY-MM-DD")}
        onChange={(e) =>
          formik.setFieldValue(
            fieldName,
            moment(e.target.value, "YYYY-MM-DD").toDate()
          )
        }
        onBlur={formik.handleBlur}
      />
    </FormControl>
  );

  /**
   * This function is used to render text field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   * @param numeric - Boolean for numeric type field
   */
  const renderTextField = (fieldName = "", label = "", numeric = false) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${label}`}
        style={{ textTransform: "capitalize", marginBottom: "5px" }}
      >
        {label}
        {((fieldName === FIELD_NAMES.stockcode) || (fieldName === FIELD_NAMES.longDesc)) && <span className="required-field-asterisk-icon">{` *`}</span>}
      </label>
      <TextField
        id={`outlined-${fieldName}`}
        name={fieldName}
        label={
          formik.touched[fieldName] && formik.errors[fieldName] ?
            String(formik.errors[fieldName]) : ""
        }
        onKeyDown={(e) => (e.key === "ArrowUp" || e.key === "ArrowDown") && e.preventDefault()}
        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
        className="hide-input-arrow"
        inputProps={{ type: numeric ? 'number' : 'text' }}
        value={formik.values[fieldName]}
        onChange={formik.handleChange}
        error={
          !!(formik.touched[fieldName] && formik.errors[fieldName])
        }
        onBlur={formik.handleBlur}
        disabled={fieldName === FIELD_NAMES.developmentType}
        style={{ backgroundColor: fieldName === FIELD_NAMES.developmentType ? "#EBEBE4" : "#FFFFFF" }}
      />
    </FormControl>
  );

  /**
   * This function is used to render toogle switch button field
   * @param fieldName - Name of the field
   * @param label - Label of the field
   */
  const renderToggleButton = (
    fieldName: string = "",
    label: string = "",
  ) => (
    <FormControl style={{ margin: "5px 5px", width: "100%" }}>
      <label
        htmlFor={`outlined-${fieldName}`}
        style={{ textTransform: "capitalize", marginBottom: "5px" }}
      >
        {label}
      </label>
      <FormControlLabel
        id={`outlined-${fieldName}`}
        control={
          <ToggleSwitchButton
            checked={formik.values[fieldName]}
            onChange={(e) => formik.setFieldValue(fieldName, e.target.checked)}
            fieldName={fieldName}
          />
        }
        label={formik.values[fieldName] ? "Yes" : "No"}
        sx={{ marginTop: "2px", width: 'fit-content' }}
      />
    </FormControl>
  );

  /**
   * This function is used to returns list of DataTypes for a given field name
   * @param attributeName - Api response field name
   */
  const getAttributeValues = (attributeName: string) => {
    if (dropDownOptions && dropDownOptions.dataFieldsTypes) {
      return dropDownOptions.dataFieldsTypes
        ?.find(df => df.field.toLowerCase() === attributeName.toLowerCase())
        ?.types
        ?.map(t => {
          return {
            id: t.id,
            description: t.description,
          }
        });
    }
    else return [];
  };

  /**
   * This function is used to returns list of Stocking Type dropdown options
   */
  const getStockingTypeDropdownOptions = () => {
    if (dropDownOptions && dropDownOptions.dataFieldsTypes) {
      return dropDownOptions.dataFieldsTypes
        ?.find(df => df.field.toLowerCase() === dataFieldNames.itemStockingType.toLowerCase())
        ?.types
        ?.filter(
          (t) =>
            t.description !== STOCKING_TYPE_HIDDEN_OPTIONS_FOR_BOM_COMPONENT.sellingLine &&
            t.description !== STOCKING_TYPE_HIDDEN_OPTIONS_FOR_BOM_COMPONENT.purchasedRawMaterial
        )
        ?.map(t => {
          return {
            id: t.id,
            description: t.description,
          }
        });
    }
    else return [];
  }

  const formFieldsRenderer = {
    stockcode: (field) => () => renderTextField(field.fieldName, field.label),
    baseCode: (field) => () => renderTextField(field.fieldName, field.label),
    longDesc: (field) => () => renderTextField(field.fieldName, field.label),
    shortDesc: (field) => () => renderTextField(field.fieldName, field.label),
    marketingDesigner: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productDesignerMkts),
    royaltyDesigner: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.royaltyDesigners),
    category: (field) => () => renderDropDown(field.fieldName, field.label, dropDownOptions.productcategories),
    function: (field) => () => renderDropDown(field.fieldName, field.label, functionDropDownOptions),
    itemStockingType: (field) => () => renderAutocomplete(field.fieldName, field.label, getStockingTypeDropdownOptions()),
    skuType: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.skuTypes),
    skuRank: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.sKURank)),
    bcRank: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.sKURank)),
    slIntroDate: (field) => () => renderDatePicker(field.fieldName, field.label),
    vendor: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.productSuppliers),
    itemVendorCost: (field) => () => renderTextField(field.fieldName, field.label, true),
    warehouse: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.warehouse)),
    inventoryItem: (field) => () => renderToggleButton(field.fieldName, field.label),
    sellingItem: (field) => () => renderToggleButton(field.fieldName, field.label),
    purchasingItem: (field) => () => renderToggleButton(field.fieldName, field.label),
    groupName: (field) => () => renderTextField(field.fieldName, field.label),
    style: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.styles),
    commission: (field) => () => renderToggleButton(field.fieldName, field.label),
    royalty: (field) => () => renderToggleButton(field.fieldName, field.label),
    pdCode: (field) => () => renderTextField(field.fieldName, field.label),
    lightSource: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.lightSource)),
    developmentType: (field) => () => renderTextField(field.fieldName, field.label),
    htsMaterial: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.htsMaterials),
    htsMaterialDtl: (field) => () => renderAutocomplete(field.fieldName, field.label, dropDownOptions.htsMaterialDetails),
    iS_220VNotAvailable: (field) => () => renderToggleButton(field.fieldName, field.label),
    prop65: (field) => () => renderAutocomplete(field.fieldName, field.label, getAttributeValues(dataFieldNames.prop65)),
  };

  const formRendererNames = Object.keys(formFieldsRenderer);

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xl">
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <DialogTitle fontSize={20}>Create New Component</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {formRendererNames.map((fieldName, index) => {
              const field = productLayoutFields.find(f => f.fieldName.toLowerCase() === fieldName.toLowerCase());
              const renderFunction = formFieldsRenderer[fieldName];
              return field && renderFunction ? (
                <Grid item xs={3} key={index}>
                  {renderFunction(field)()}
                </Grid>
              ) : null;
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit" disabled={disableSubmit} style={{ margin: "8px 5px", width: "135px" }}>
            {"Submit"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateBOMComponentModal;
