import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";

const CustomToolbar = () => (
    <div style={{ padding: 5, display: "flex", justifyContent: "flex-start" }}>
      <GridToolbarContainer>
        <GridToolbarQuickFilter sx={{ margin: "5px 30px 5px 5px",
          width: "400px",
         }} />
      </GridToolbarContainer>
    </div>
);
  
export default CustomToolbar;