import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Grid,
  } from "@mui/material";

  interface CategoryModalProps{
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;
  }

  const CategoryModal = (props:CategoryModalProps & { isButtonDisabled: boolean })=>{
    const {isOpen, onClose, formik, isButtonDisabled} = props

    return(
        <>
            <Dialog open={isOpen} onClose={onClose} fullWidth>
                <DialogActions>
                    <Button onClick = {onClose}>x</Button>
                </DialogActions>
                <form onSubmit = {formik.handleSubmit}>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        name = "description"
                        label = {
                            formik.touched.description && formik.errors.description ?
                            formik.errors.description : "Enter Category Name"
                        }
                        type = "text"
                        fullWidth
                        value = {formik.values.description}
                        onChange = {formik.handleChange}
                        error = {formik.touched.description && formik.errors.description}
                        onBlur={formik.handleBlur}
                    />
                    <Grid container spacing = {2}>
                        <Grid item xs = {6}>
                            <TextField
                                margin="dense"
                                id="itemGrpCode"
                                name = "itemGrpCode"
                                label = {
                                    formik.touched.itemGrpCode && formik.errors.itemGrpCode ?
                                    formik.errors.itemGrpCode : "Enter SAP Code"
                                }
                                type = "text"
                                fullWidth
                                value = {formik.values.itemGrpCode}
                                onChange = {formik.handleChange}
                                error = {formik.touched.itemGrpCode && formik.errors.itemGrpCode}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                        <Grid item xs = {6}>
                            <TextField
                                margin="dense"
                                id="pimKey"
                                name = "pimKey"
                                label = {
                                    formik.touched.pimKey && formik.errors.pimKey ?
                                    formik.errors.pimKey : "Enter PIM Key"
                                }
                                type = "text"
                                fullWidth
                                value = {formik.values.pimKey}
                                onChange = {formik.handleChange}
                                error = {formik.touched.pimKey && formik.errors.pimKey}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                    </Grid>
                    
                    { 
                        formik.values.id> 0 ? 
                        null:
                            <TextField
                                margin="dense"
                                id="sort"
                                name = "sort"
                                label = {
                                    formik.touched.sort && formik.errors.sort ?
                                    formik.errors.sort : "Enter Sort"
                                }
                                type = "number"
                                fullWidth
                                value = {formik.values.sort}
                                onChange = {formik.handleChange}
                                error = {formik.touched.sort && formik.errors.sort}
                                onBlur={formik.handleBlur}
                            />
                        }
                    <h4>Set Active</h4>
                    <RadioGroup
                        row  
                        name = "isActive"
                        value = {formik.values.isActive}
                        onChange = {(event) => formik.handleChange(event)}
                    >
                        <FormControlLabel value ={true} control={<Radio/>} label = "Active"/>
                        <FormControlLabel value ={false} control={<Radio/>} label = "Inactive"/>
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isButtonDisabled}
                    >
                        {formik.values.id > 0 ? "Update Category": "Add Category"}
                    </Button>
                </DialogActions>
                </form>
            </Dialog>
        </>
    )
  };

export default CategoryModal;