import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, Switch, TextField } from "@mui/material";

interface SkuTypeModalProps{
    isOpen: boolean;
    onClose: VoidFunction;
    formik: any;
}

const SkuTypeModal = (props: SkuTypeModalProps & { isButtonDisabled: boolean } ) => {
    const {isOpen, onClose, formik, isButtonDisabled} = props

    return (
        <>
            <Dialog open = {isOpen} onClose = {onClose} fullWidth>
                <DialogActions>
                    <Button onClick = {onClose}>x</Button>
                </DialogActions>
                <form onSubmit = {formik.handleSubmit}>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin = "dense"
                            id = "name"
                            name = "name"
                            label = {
                                formik.touched.name && formik.errors.name? 
                                formik.errors.name : "Enter Sku Type Name"
                            }
                            type = "text"
                            fullWidth
                            value = {formik.values.name}
                            onChange = {formik.handleChange}
                            error = {formik.touched.name && formik.errors.name}
                            onBlur = {formik.handleBlur}
                        />
                        <TextField
                            margin = "dense"
                            id = "code"
                            name = "code"
                            label = {
                                formik.touched.code && formik.errors.code? 
                                formik.errors.code : "Enter Code"
                            }
                            type = "text"
                            fullWidth
                            value = {formik.values.code}
                            onChange = {(e) => formik.setFieldValue("code", e.target.value.toUpperCase())}
                            error = {formik.touched.code && formik.errors.code}
                            onBlur = {formik.handleBlur}
                        />
                        <h4>Sku Select</h4>
                        <RadioGroup
                            row
                            name = "skuSelect"
                            value = {formik.values.skuSelect}
                            onChange = {
                                (event) => formik.handleChange(event)
                            }
                        >
                            <FormControlLabel value = {"Yes"} control = {<Radio/>} label = "Yes"/>
                            <FormControlLabel value = {"No"} control = {<Radio/>} label = "No"/>
                        </RadioGroup>
                        <h4>Set Active</h4>
                        <RadioGroup
                            row
                            name = "isActive"
                            value = {formik.values.isActive}
                            onChange = {
                                (event) => formik.handleChange(event)
                            }
                        >
                            <FormControlLabel value = {true} control = {<Radio/>} label = "Active"/>
                            <FormControlLabel value = {false} control = {<Radio/>} label = "Inactive"/>
                        </RadioGroup>
                        <DialogActions>
                            <Button variant="contained"
                                type="submit"
                                disabled={isButtonDisabled}
                            >
                            {
                                formik.values.id > 0 ?
                                "Update Sku Type" : "Add Sku Type"
                            }
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>

            </Dialog>
        </>
    )
};

export default SkuTypeModal;