import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import imageNotAvailable from "../no-image.png";

type ImageSliderProps = {
    images: ImageDetail[];
    isOpen: boolean;
    selectedImageSlide: number;
    onClose: () => void;
};

export type ImageDetail = {
    imageUrl: string;
    fileName: string;
}

const settings = {
    showArrows: true,
    interval: 5000,
    dynamicHeight: false,
    stopOnHover: true,
    infiniteLoop: true,
    transitionTime: 500,
    showThumbs: false,
    showIndicators: false,
    swipeable: true,
    emulateTouch: true,
    autoPlay: true,
};

const ImageSliderView = (props: ImageSliderProps) => {

    const {
        images,
        isOpen,
        selectedImageSlide,
        onClose
    } = props;

    if (!isOpen) return null;

    return (
        <>
            <div className="image-slider-overlay">
                <div className="image-slider-container">
                    <span className="close-btn" onClick={onClose}>&times;</span>
                    <div className="image-slider">
                        <Carousel {...settings} width={600} selectedItem={selectedImageSlide}>
                            {images.map((image, index) => (
                                <div key={index}>
                                    <img
                                        src={image.imageUrl}
                                        alt={`Attachment ${image.fileName}`}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = imageNotAvailable;
                                        }}
                                    />
                                    <p className="legend">{image.fileName}</p>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImageSliderView;