import { Badge, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ManageNotificationModal from "./ManageNotificationModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { NotificationPreference } from "../models/NotificationPreference";


const ManageNotification = () => {
    const [manageNotificationModal, setManageNotificationModal] = useState(false);

    const [notifications, setNotifications]  = useState<NotificationPreference[]>([]);

    useEffect(() => {
        fetchNotificationPreferences();
    },[]);

    const fetchNotificationPreferences = async () => {
        const sessionInfo: any = JSON.parse(
            window?.localStorage.getItem("session") || "{}"
        );
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Notification/preferences`, {
            method: 'GET',
            headers: {
            "Authorization": "Bearer " + sessionInfo.token,
            }
        });

        if(response.ok) {
            const responseData = await response.json();
            const notificationResponse = responseData.data || [];
            setNotifications(notificationResponse);
        };
    };

    const handleManageNotificationModal = async () => {
        await fetchNotificationPreferences();
        formik.setValues({notifications});
        setManageNotificationModal(true);
    };

    const closeManageNotificationModal = () => {
        setManageNotificationModal(false);
    };
    

    const formik = useFormik({
        initialValues: {
            notifications: []
        },
        onSubmit: async (formValues) => {
            try{
                const sessionInfo: any = JSON.parse(
                    window?.localStorage.getItem("session") || "{}"
                );
                const response = await fetch(`${process.env.REACT_APP_API_URL}/Notification/preferences`, {
                    method: 'PUT',
                    headers: {
                        "Authorization": "Bearer " + sessionInfo.token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(formValues.notifications) 
                });
        
                if(response.ok) {
                    setManageNotificationModal(false);
                    toast.success("Notification Preferences Updated Successfully");
                    await fetchNotificationPreferences()
                };
            }catch (error) {
                console.log("Error in Updating Notification Preferences");
            } 
        }
    });

    return(
        <>
            {manageNotificationModal && <ManageNotificationModal 
                isOpen={manageNotificationModal} 
                onClose={closeManageNotificationModal} 
                formik = {formik}               
            />}

            <IconButton
                    id="basic-button"
                    title="Notifications"
                    aria-haspopup="true"
                    onClick={handleManageNotificationModal}>
                <Badge>
                    <EditNotificationsIcon sx={{ color: "#484848" }} />
                </Badge>
            </IconButton> 
        </>
    );
};

export default ManageNotification;