import { useEffect, useState } from "react";
import { HTSMaterial } from "../../../models/HTSMaterial";
import { SettingGrid } from "../../../components/ManageSettingComponents/SettingGrid";
import api from "../../../services/ApiService";
import { ApiResponse } from "../../../models/ApiResponse";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import moment from "moment";
import { Button, Switch } from "@mui/material";
import { toast } from "react-toastify";
import HTSMaterialModal from "./HTSMaterialModal";
import DeleteValidationModal from "../../../components/ManageSettingComponents/DeleteValidationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SessionDetails } from "../../../models/SessionDetails";
import { DATE_TIME_FORMAT } from "../../../utils/constants";
import { GRID_ACTION_DELETE_ICON, GRID_ACTION_EDIT_ICON } from "../../../components/GridUtilityComponents";
import { Permissions } from "../../../models/Enum";
import SessionService from "../../../services/SessionService";

export default function HTSMaterialSetting() {
  const [htsMaterialList, setHTSMaterialList] = useState<HTSMaterial[]>([]);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const manageAppSetting = SessionService.getInstance().hasPermission(Permissions.ManageAppSettings);

  const HTSMaterialGridCollumns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "description", headerName: "HTS Material", width: 300 },
    {
      field: "isActive",
      headerName: "Is Active",
      sortable: false,
      width: 200,
      renderCell: (params: any) => (
        <>
          <Switch
            checked={params.value}
            onChange={async (e) => {
              await updateHTSMaterialActive(params.row, e.target.checked);
            }}
            color={"primary"}
            inputProps={{ "aria-label": "controlled" }}
            disabled = {!manageAppSetting}
          />
        </>
      ),
    },
    {
      field: "updatedDate",
      headerName: "Updated Date",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format(DATE_TIME_FORMAT),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      getActions: (params: any) => [
        GRID_ACTION_EDIT_ICON(params, handleEditClickOpen),
        GRID_ACTION_DELETE_ICON(params, handleDeleteModalOpen)
      ],
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getHTSMaterialRepsonse = await api.get<ApiResponse<HTSMaterial[]>>(
      "/Picklist/hts-materials"
    );
    setHTSMaterialList(getHTSMaterialRepsonse?.data || []);
  };

  const handleEditClickOpen = (gridParams: any) => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
    formik.setValues({ ...gridParams.row });
  };

  const handleAddClickOpen = () => {
    formik.resetForm();
    setShowAddUpdateModal(true);
    setDisableSubmit(false);
  };

  const handleDeleteModalOpen = (gridParams: any) => {
    setDeleteModalOpen(true);
    formik.setValues({ ...gridParams.row });
  };

  const handleCloseModal = () => {
    setShowAddUpdateModal(false);
    setDeleteModalOpen(false);
    formik.resetForm();
  };

  const updateHTSMaterialActive = async (
    row: HTSMaterial,
    isActive: boolean
  ) => {
    row.isActive = isActive;
    try {
      api
        .put<ApiResponse<HTSMaterial>>("/Picklist/hts-material", row)
        .then(async (response: any) => {
          if (response.isSuccess) {
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      console.error("Exception from hts material", err);
    }
  };

  const deleteSubmit = () => {
    try {
      api
        .delete<ApiResponse<SessionDetails>>(
          `/Picklist/hts-material/${formik.values.id}`
        )
        .then(async (response: any) => {
          if (response.isSuccess) {
            setDeleteModalOpen(false);
            formik.resetForm();
            toast.success(response.message);
            await fetchData();
          } else {
            throw new Error(response.message);
          }
        })
        .catch(() => {});
    } catch (err: any) {
      console.error("Exception from hts material", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      description: "",
      isActive: true,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .trim()
        .required("HTS Material is required")
        .min(1, "HTS Material must be at least 1 characters")
        .max(40, "HTS Material must be at most 40 characters")
        .test(
          "check-description-duplicate",
          "HTS Material already exists",
          (value) => {
            return !htsMaterialList.some(
              (item) =>
                item.description.trim().toLowerCase() ===
                  value.trim().toLowerCase() && item.id !== formik.values.id
            );
          }
        ),
    }),
    onSubmit: (values) => {
      setDisableSubmit(true);
      if (values.id > 0) {
        api
          .put<ApiResponse<number>>("/Picklist/hts-material", {
            id: values.id,
            description: values.description.trim(),
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      } else {
        api
          .post<ApiResponse<number>>("/Picklist/hts-material", {
            description: values.description,
            isActive: JSON.parse(`${values.isActive}`),
          })
          .then(async (response: any) => {
            if (response.isSuccess) {
              setShowAddUpdateModal(false);
              formik.resetForm();
              toast.success(response.message);
              await fetchData();
            } else {
              throw new Error(response.message);
            }
          })
          .catch(() => {});
      }
    },
  });

  return (
    <>
      <div>
        {manageAppSetting && <Button variant="contained" onClick={handleAddClickOpen}>
          Add HTS Material
        </Button>}
        <HTSMaterialModal
          isOpen={showAddUpdateModal}
          onClose={handleCloseModal}
          formik={formik}
          isButtonDisabled={disableSubmit}
        />
        <DeleteValidationModal
          addModalOpen={deleteModalOpen}
          handleCloseModal={handleCloseModal}
          deleteSubmit={deleteSubmit}
          fieldIdentifier={"HTS Material"}
        />
      </div>
      <SettingGrid
        data={htsMaterialList}
        column={HTSMaterialGridCollumns}
        sortByField="id"
        sortByOrder="desc"
        columnVisibility={{
          actions: manageAppSetting,
        }}
      />
    </>
  );
}
