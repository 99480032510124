import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import api from "../services/ApiService";
import { ApiResponse } from "../models/ApiResponse";
import { BomGridView } from "../models/Items/BomGridView";
import { debounce } from 'lodash';

interface AutoCompleteStockCodeProps {
    bomList?: BomGridView[];
    selectedItem: (item: BomGridView | null) => void;
    searchTerm: string;
    setSearchTerm: (newValue: string) => void;
};

const AutoCompleteStockCode = (props: AutoCompleteStockCodeProps) => {
    const {bomList, selectedItem, searchTerm, setSearchTerm} = props;
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     const fetchResults = async () => {
    //         if (!searchTerm.trim()) {
    //             setSearchResult([]); 
    //             setLoading(false);
    //             return;
    //         }
    //         setLoading(true);
    //         const encodedSearchTerm = encodeURIComponent(searchTerm.trim().replace(/\./g, '%2E').replace(/\//g, '%2F').replace(/\\/g, '%5C'));
    //         const url = `/Item/stockcode-search/${encodedSearchTerm}`;
        
    //         try {
    //             const response = await api.get<ApiResponse<BomGridView[]>>(url);
    //             if (response.isSuccess) {
    //                 setSearchResult(response.data);
    //             } else {
    //                 setSearchResult([]); 
    //                 console.error(response.message);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching results:', error);
    //         }finally {
    //             setLoading(false); 
    //         }
    //     };

    //     fetchResults(); 
    //   },[searchTerm]);

    //adding debounce to search
    const fetchResults = async (term: string) => {
        if (!term.trim()) {
            setSearchResult([]); 
            setLoading(false);
            return;
        }
        setLoading(true);
        const encodedSearchTerm = encodeURIComponent(term.trim().replace(/\./g, '%2E').replace(/\//g, '%2F').replace(/\\/g, '%5C'));
        const url = `/Item/stockcode-search/${encodedSearchTerm}`;
    
        try {
            const response = await api.get<ApiResponse<BomGridView[]>>(url);
            if (response.isSuccess) {
                setSearchResult(response.data);
            } else {
                setSearchResult([]); 
                console.error(response.message);
            }
        } catch (error) {
            console.error('Error fetching results:', error);
        } finally {
            setLoading(false); 
        }
    };

    const debouncedFetchResults = useCallback(
        debounce((term) => fetchResults(term), 400),
        []
    );

    useEffect(() => {
        debouncedFetchResults(searchTerm);

        return () => {
            debouncedFetchResults.cancel();
        };
    }, [searchTerm, debouncedFetchResults]);

    //validate already selected stockcode in BOM
    const isStockCodeInList = searchResult.some(item => item.stockcode === searchTerm);
    const noOptionsText = !searchTerm ? "Enter Stock Code" : isStockCodeInList ? "Already In List" : "Not Found";

    return(
        <>
            <Autocomplete 
                id = 'autocomplete'
                size="small"
                style={{ width: '500px' }}
                getOptionLabel={(optionList) => optionList.stockcode || ''}
                options={searchResult.filter(options => !bomList.some(item => item.stockcode ===options.stockcode))}          
                inputValue={searchTerm}
                renderInput={(params) => <TextField {...params} label="Component Sku Stock Code" />}
                onInputChange={(event, newInputValue, reason) => {
                    //if reason is input then new value else null
                    if(reason === 'input') {
                        //const filteredValue = newInputValue.replace(/[^a-zA-Z0-9/]/g, '');
                        setSearchTerm(newInputValue);
                    }
                }}
                onChange={(event, newValue) => {
                    setSearchTerm(newValue.stockcode);
                    selectedItem(newValue);
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.stockcode}
                        {option.longDesc && " - " + option.longDesc}
                    </li>
                )}
                noOptionsText= {noOptionsText}
                //disable clearable because when clear null it would error
                disableClearable = {true}
                loading = {loading}
            />
        </>
    )

};

export default AutoCompleteStockCode;