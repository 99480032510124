import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
 } from "@mui/material";

interface MarketingDesignerProps {
    isOpen: boolean,
    onClose: VoidFunction,
    formik: any;
};

const MarketingDesignerModal = (props: MarketingDesignerProps & { isButtonDisabled: boolean }) => {
    const {isOpen, onClose, formik, isButtonDisabled} = props;

    return(
        <>
            <Dialog open = {isOpen} onClose={onClose} fullWidth>
                <DialogActions>
                    <Button onClick = {onClose}>x</Button>
                </DialogActions>
                <form onSubmit = {formik.handleSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs = {8}>
                            <TextField
                                autoFocus
                                margin = "dense"
                                id = "designer"
                                name = "designer"
                                label = {
                                    formik.touched.designer && formik.errors.designer ?
                                    formik.errors.designer : "Enter Designer Name"
                                }
                                type = "text"
                                fullWidth
                                value = {formik.values.designer}
                                onChange = {formik.handleChange}
                                onBlur = {formik.handleBlur}
                                error = {formik.touched.designer && formik.errors.designer}
                            />
                        </Grid>
                        <Grid item xs = {4}>
                            <TextField
                                margin="dense"
                                id = "prefix"
                                name = "prefix"
                                label = {
                                    formik.touched.prefix && formik.errors.prefix ?
                                    formik.errors.prefix : "Enter Prefix"
                                }
                                type = "text"
                                value = {formik.values.prefix.toUpperCase()}
                                onChange = {formik.handleChange}
                                onBlur = {formik.handleBlur}
                                error = {formik.touched.prefix && formik.errors.prefix}
                            />
                        </Grid>
                    </Grid>
                    <h4>Set Active</h4>
                    <RadioGroup
                        row
                        name = "isActive"
                        value = {formik.values.isActive}
                        onChange = {(event) => formik.handleChange(event)}
                    >
                        <FormControlLabel value = {true} control = {<Radio/>} label = {"Active"}/>
                        <FormControlLabel value = {false} control = {<Radio/>} label = {"Inactive"}/>
                    </RadioGroup>
                    <DialogActions>
                        <Button variant = "contained" type = "submit" disabled={isButtonDisabled}>
                            {formik.values.id > 0 ? "Update Designer" : "Add New Designer"}
                        </Button>
                    </DialogActions>
                </DialogContent>
                </form>
            </Dialog>
        </>
    );
};

export default MarketingDesignerModal;