import { USER_ROLES } from "../utils/constants";
import { jwtDecode } from "jwt-decode";

export const accessValidate = (menu: string) => {
  const userData = localStorage.getItem("puser") || "";
  if (userData) {
    const user = JSON.parse(userData);
    const access = user?.permissions?.split(",");
    if (access?.length > 0) {
      return access.includes(menu);
    }
  }
  return true;
};

export const currentUserRole = () => {
  let roles: string[] = [];
  const userData = localStorage.getItem("session");
  if (userData) {
    const user = JSON.parse(userData);
    const payload: any = jwtDecode(user?.accessToken);
    roles = payload?.roles;
  }
  return roles && roles.length ? roles : [USER_ROLES.USER];
};

export const isTokenExpired = () => {
  try {
    const session = JSON.parse(localStorage.getItem('session') || "{}");
    const token = session.token;
    
    if(token) {
      const decodedToken: any = jwtDecode(token);

      // Check if the token is expired
      if (Date.now() >= decodedToken.exp * 1000) {
        // Token is expired
        return true;
      }
    }
    // Token is not expired yet
    return false;
  } catch (error) {
    // An error occurred during decoding (e.g., invalid signature, expired token)
    console.error('Error decoding or validating JWT token:', error);
    return true;
  }
}
